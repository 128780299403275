<template>
  <div
    v-show="isShown"
    class="gvgroup rounded-xl border-2 bg-white"
    :class="{
      'border-blue-400': !(isError || isInvalidGap),
      'border-red-600': isError || isInvalidGap,
    }"
  >
    <!-- portals -->
    <!-- Since two form-builders (normal and preview) can be shown at the same time, we must use different portals -->
    <template v-if="isShown">
      <portal
        :to="`${originalBlock.block_fe_id}_name${previewForm ? '-preview' : ''}`"
      >
        <h3
          v-if="originalBlock.block_name"
          class="text-lg capitalize font-semibold text-base-content mb-2 blockNameLable"
        >
          {{ originalBlock.block_label }}
        </h3>
      </portal>
      <portal
        :to="`${originalBlock.block_fe_id}_gaps${previewForm ? '-preview' : ''}`"
      >
        <GapErrors
          ref='gapErrorsRef'
          class="block-gaps mb-4"
          :class="{'form-field--invalid': gapErrorMsg === 'ERROR.GAP_VALIDATION'}"
          @select:gap="selectGap"
          :key="gapErrors.id"
          :message="gapErrors.message"
          :gapGroup="timelineData"
          @reload="scrollToGaps"
          :previewForm="previewForm"
          :blockData="originalBlock"
          :formAlreadySubmitted="formAlreadySubmitted"
          :modalEdit="modalEdit"
          :isError="isError || isInvalidGap"
        />
      </portal>
    </template>
    <!-- /portals -->

    <h3
      class="gvgroup__title py-5 px-7 rounded-t-xl font-semibold border-b border-line"
      :class="{
        'border-red-600 bg-error': isError || isInvalidGap,
        'bg-blue-100': !(isError || isInvalidGap),
      }"
    >
      {{ groupTitle }}
    </h3>
    <div class="gvgroup__list rounded-b-xl w-full">
      <div
        v-for="(el, posInGroup) in sortedEntities"
        :key="el.entity.block_fe_id"
        class="gv-block"
        :class="{
          'border-b border-line': !(posInGroup === sortedEntities.length - 1),
          'cursor-pointer': isMobile,
        }"
        :ref="el.entity.block_fe_id"
        @click="selectAction('edit', el)"
      >
        <div
          class="gv-block__header px-7 py-5"
          :class="{
            'text-blue-600': !(el.gap?.invalid_gap || checkBlockValidation(el)),
            'bg-error': el.gap?.invalid_gap,
            'text-red-600': el.gap?.invalid_gap || checkBlockValidation(el),
          }"
        >
          <div class="gap-title header-col" >
            <div
              class="header-col__label font-medium"
              v-tippy="{
                content: groupTitle,
                placement: 'bottom'
              }"
            >
              {{ groupTitle }}
            </div>
            <div class="header-col__value text-base-content">
              {{ getBlockLabel(el.entity) || '--'}}
            </div>
          </div>
          <div class="gap-fromDate header-col">
            <div class="header-col__label font-medium">
              From
            </div>
            <div class="header-col__value text-base-content">
              {{ getFromDate(el.entity) || '--' }}
            </div>
          </div>
          <div class="gap-toDate header-col">
            <div class="header-col__label font-medium">
              To
            </div>
            <div class="header-col__value text-base-content">
              {{ getToDate(el.entity) || '--' }}
            </div>
          </div>
          <div class="gap-actionItems header-col text-right">
            <div class="gap-actionItems__container">
              <div class="header-col__label font-medium gap-actionItems__label">
                Action
              </div>
              <div class="gap-actionItems__buttons text-base-content">
                <template v-if="!isMobile">
                  <EditButton
                    :class="{
                      'text-blue-600': !(el.gap?.invalid_gap || checkBlockValidation(el)),
                      'text-red-600': el.gap?.invalid_gap || checkBlockValidation(el),
                    }"
                    @click.native.stop="toggleBlock(el, true)"
                    :disabled="isDisabled"
                  >Edit</EditButton>
                  <RemoveButton
                    v-if="!el.gap?.invalid_gap"
                    @click.stop="removeTimeline(el)"
                    :disabled="isDisabled"
                  />
                </template>
                <DropdownMenu
                  v-else
                  :class="{
                    'text-red-600': el.gap?.invalid_gap || checkBlockValidation(el),
                    'text-blue-600': !(el.gap?.invalid_gap || checkBlockValidation(el)),
                  }"
                  :right="true"
                  :options="getActionOptions(el)"
                  @select="selectAction($event, el)"
                  @click.native.stop
                />
              </div>
            </div>
            <div
              v-if="!isMobile"
              class="gap-actionItems__icon"
            >
              <ToggleButton
                :expanded="expandedBlocks[el.entity.block_fe_id]"
                @click.native="toggleBlock(el)"
              />
            </div>
          </div>
        </div>
        <div
          v-show="expandedBlocks[el.entity.block_fe_id] && !isMobile"
          class="gv-block__block-container pb-5"
        >
          <div
            class="px-7 gv-block__block"
            colspan="4"
          >
            <Block
              :ref="getBlockRef(el.entity.block_fe_id)"
              :key="!!el.gap?.invalid_gap"
              :class="{'blockCollaps': posInGroup === 0, dynamicBlock: previewForm}"
              :blockData="el.entity"
              :section="section"
              :path="[
                ...path,
                { field: 'block_fe_id', value: el.entity.block_fe_id },
              ]"
              :isMulti="checkIfMulti(el.entity, entities)"
              :validations="el.validations?.entities || null"
              :parentValidations="validations"
              :previewMode="previewMode"
              :previewForm="previewForm"
              :grouped="showGroup"
              :gapValidationGroup="true"
              :posInGroup="posInGroup"
              :scrollToGap="scrollToGap"
              @disableScrollToGap="disableScrollToGap"
              :gapErrorMsg="gapErrorMsg"
              :formAlreadySubmitted="formAlreadySubmitted"
              @checkCheckboxId="checkCheckboxId"
              :checkBoxId="checkBoxId"
              @emtpyBlockFieldId="emtpyBlockFieldId"
              @addTimeline="updateTimeline(el)"
              :hidden="!(expandedBlocks[el.entity.block_fe_id] && !isMobile)"
            />
          </div>
          <div
            v-if="el.gap?.invalid_gap"
            class="gv-block__block-controls px-7"
          >
            <AddButton
              :disabled="!isMulti || !isSource"
              @click="fillGap(el)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- TODO check if all the props are necessary -->
    <EditBlockModal
      v-if="isModalOpened"
      :toAdd="selectedEntity.gap?.invalid_gap"
      @add="fillFromModal(selectedEntity)"
      @save="saveFromModal"
      @close="closeModal"
      @cancel="cancelModal"
      @error="scrollToModalErrors"
      :item="selectedEntity"
      :isPeriodProvided="isPeriodProvided"
      :isMulti="isMulti"
      :disabled="!isSource"
    >
      <Block
        ref="modalBlock"
        :class="{'blockCollaps': true, dynamicBlock: previewForm}"
        :blockData="selectedEntity.entity"
        :section="section"
        :path="[
          ...path,
          { field: 'block_fe_id', value: selectedEntity.entity.block_fe_id },
        ]"
        :isMulti="checkIfMulti(selectedEntity.entity, entities)"
        :validations="selectedEntity.validations?.entities || null"
        :parentValidations="validations"
        :previewMode="previewMode"
        :previewForm="previewForm"
        :grouped="showGroup"
        :gapValidationGroup="true"
        :posInGroup="0"
        :scrollToGap="scrollToGap"
        @disableScrollToGap="disableScrollToGap"
        :gapErrorMsg="gapErrorMsg"
        :formAlreadySubmitted="formAlreadySubmitted"
        @checkCheckboxId="checkCheckboxId"
        :checkBoxId="checkBoxId"
        @emtpyBlockFieldId="emtpyBlockFieldId"
        @addTimeline="updateFromModal"
      />
    </EditBlockModal>
  </div>
</template>
<script>
// TODO:
/* 
  - Handle gap click.
  - On "next/save" check validation only of valid blocks and
  presence of at least one invalid block with invalid_gap_data.
  Because if the gap data of an invalid block doesn't include invalid_gap_data
  that means it may not be filled.
  - Take the block_repeat_min and block_repeat_max into account.
  - Check why modal is being closed twice.
*/
// * If the current group is linked we use gapValues of the source group this group is linked to

const { DateTime } = require('luxon');
// import deleteModal from "./deleteModal"
import store, { mutations } from "../../store";
import moment from 'moment';
import { cloneDeep } from "lodash";
import {
  checkIfMulti,
  getEntityByKeyList,
  getAllEntitiesByKeyList,
  resetValues,
  useValuesFrom,
  isJson,
} from "../../utils";
import Block from "../formBlock.vue";
import ToggleButton from '@shared/components/buttons/ToggleArrowButton.vue';
import EditButton from '@shared/components/buttons/EditButton.vue';
import RemoveButton from '@shared/components/buttons/RemoveButton.vue';
import AddButton from './components/AddButton.vue';
import GapErrors from '../gapErrors.vue';
import DropdownMenu from '@shared/components/DropdownMenu.vue';
import TrashIcon from '@shared/assets/trash.svg';
import EditIcon from '@shared/assets/edit-pencil-alt.svg';
import EditBlockModal from "./components/EditBlockModal.vue";
import { CASCADING_FIELD_WITH_ERROR_SELECTOR, SCROLL_OPTIONS } from "@shared/components/form-builder/constants";

// field_base_type currently possible to use as a block label:
// const blockLabelFields = ['Input', 'Large text', 'Number', 'Date', 'Email'];

export default {
  components:{
    Block,
    GapErrors,
    ToggleButton,
    EditButton,
    RemoveButton,
    AddButton,
    DropdownMenu,
    EditBlockModal,
  },
  props:{
    groupData: {
      type: Object,
      required: true
    },
    path: Array,
    validations: {
      type: Object,
      default: null,
    },
    gapErrorMsg: String,
    scrollToGap: {
      type: Boolean,
      default: true,
    },
    // is used to preview form before creating it in an admin panel:
    previewMode: {
      type: Boolean,
      default: false
    },
    // is used to preview filled form before its submitting:
    previewForm:{
      type: Boolean,
      default: false
    },
    formAlreadySubmitted: {
      type: Boolean,
    },
    section: {
      type: Object,
      required: true
    },
    // the block_id which is used to make a group:
    groupId: {
      type: String,
      required: true
    },
    checkBoxId: {
      type: String
    },
    // if we use sticky/fixed elements take it into account when scrollIntoView
    fixedTimeline: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      DateTime,
      store,
      isCollapsed: false,
      expandedBlocks: {}, // by default all the blocks are collapsed; add expanded blocks here
      modalEdit: false,
      // show the copy in the list instead of selected block to not to show all temporary changes
      // + it allow us to use the same validations in modal that we configured in form-builder
      selectedEntity: null,
      selectedEntityCopy: null,
      toResetExpanded: false, // is used to control if the timeline has been changed and resetExpandedBlocks() shoud be called
      showEditModal: false, // is used to not to show Edit Modal on mobile by default
    }
  },
  mounted() {
    // this.resetExpandedBlocks();
    this.resetExpandedBlocks({ scroll: false, scrollToField: false });
  },
  watch: {
    startDateFieldValue(newVal) {
      this.$set(this.gapGroupValues, 'start', newVal ? new Date(newVal) : null);
      this.findGapPeriods(this.gapGroupValues);
      if (newVal) {
        this.resetExpandedBlocks();
      }
    },
  },
  computed: {
    isDisabled() {
      return !this.isSource || this.formAlreadySubmitted;
    },
    isMobile() {
      return store.isMobile;
    },
    isShown() {
      return this.originalBlock.visible;
    },
    linked_blocks() {
      return store.linked_blocks;
    },
    sections_data() {
      return store.list;
    },
    linkedOriginalBlockGroup() {
      // check block_id to include copies in calculations:
      return (
        this.linked_blocks.find((group) =>
          group.includes(this.originalBlock.block_id)
        ) || null
      );
    },
    // the first linked block of a group which values will be used:
    linkedOriginalBlock() {
      if (!this.linkedOriginalBlockGroup || !this.originalBlock.use_value_from) return null;
      // check block_fe_id because only original block has same block_id and block_fe_id:
      return getEntityByKeyList(this.sections_data, {
        block_fe_id: this.originalBlock.use_value_from,
      }) || null;
    },
    // check if the current block is source (first linked) block we use values from
    isSource() {
      return !this.linkedOriginalBlock;
    },
    sourceOriginalBlock() {
      return this.linkedOriginalBlock || this.originalBlock;
    },
    isModalOpened() {
      return this.selectedEntity && this.isMobile && this.showEditModal;
    },
    isPeriodProvided() {
      return !!(this.gapGroupValues?.start && this.gapGroupValues?.end);
    },
    utils() {
      return store.utils;
    },
    entities() {
      return this.groupData.list;
    },
    // TODO sort by date value
    sortedEntities() {
      return this.entities
        .map((el) => {
          // show the copy in the list instead of selected block to not to show all temporary changes:
          if (this.isModalOpened && this.selectedEntity.entity.block_fe_id === el.entity.block_fe_id) { // another check can be set instead
            return this.selectedEntityCopy;
          }
          let gap = this.gapGroupValues?.timeline?.find((gap) => gap.block.block_fe_id === el.entity.block_fe_id) || null
          if (!this.isSource) {
            gap = this.gapGroupValues?.timeline?.find((gap) => gap.block.block_fe_id === el.entity.use_value_from) || null
          }

          return {
            ...el,
            validations: this.validations[el.idx],
            gap,
          }
        })
        .sort((a, b) => {
          const fieldA = this.getDateRangeField(a.entity);
          const fieldB = this.getDateRangeField(b.entity);
          const valueA = this.getDateFieldValue(fieldA) || {};
          const valueB = this.getDateFieldValue(fieldB) || {};
          return (valueA.from ? new Date(valueA.from) : 0) - (valueB.from ? new Date(valueB.from) : 0);
        });
    },
    isError() {
      return this.sortedEntities.some((el) => el.validations.$error);
    },
    isInvalid() {
      return this.sortedEntities.some((el) => el.validations.$invalid);
    },
    originalBlock() {
      // the original block has the block_id equel to block_fe_id:
      const original = this.entities.find((el) => el.entity?.block_fe_id === this.groupId) || this.entities[0];
      return original.entity;
    },
    maxCount() {
      return this.originalBlock.block_repeat_max;
    },
    validCount() {
      return this.sortedEntities.filter((el) => el.gap && !el.gap.invalid_gap).length;
    },
    isMulti() {
      if (!this.maxCount) return true;
      return this.validCount < this.maxCount;
    },
    groupTitle() {
      return this.originalBlock.block_title;
    },
    gapValidationField() {
      return this.currentGapGroupValues.originalField;
    },
    gapValidation() {
      return this.gapValidationField?.gap_validation || null;
    },
    startDateField() {
      if (!this.gapValidation?.start_date) return null;
      return getEntityByKeyList(this.section.entities, {
        field_fe_id: this.gapValidation.start_date,
      });
    },
    startDateFieldValue() {
      return this.startDateField?.value;
    },
    tillPresent() {
      return !!(this.gapValidation?.till_present);
    },
    relative() {
      return !!(this.gapValidation?.relative);
    },
    // gap group values to display. Use source value if this block is linked and uses another block's values
    gapGroupValues: {
      get() {
        return store.gap_values[this.sourceOriginalBlock.block_id] || null;
      },
      set(val) {
        this.$set(store.gap_values, this.sourceOriginalBlock.block_id, val);
      },
    },
    // is used to check gap validation group config (like field/block entity):
    currentGapGroupValues() {
      return store.gap_values[this.originalBlock.block_id] || null;
    },
    // is used for gapErrors component to avoid extra redraws when using modal window
    timelineData() {
      return {
        ...this.gapGroupValues,
        timeline: this.gapGroupValues.timeline.map((el) => {
          if (!this.isModalOpened) return el;
          const isSelected = el.block?.block_fe_id === this.selectedEntity.entity.block_fe_id;
          return isSelected ? this.selectedEntityCopy.gap : el;
        }),
      }
    },
    gapErrors() {
      return store.gap_errors;
    },
    showGroup() {
      return this.entities.length > 1;
    },
    isInvalidGap() {
      return !!this.gapGroupValues?.timeline?.some((gap) => gap.invalid_gap);
    },
  },
  methods: {
    checkIfMulti,
    getBlockRef(id) {
      return `block_${id}`;
    },
    findGapPeriods(payload) {
      if (!this.isSource) return;
      this.utils.findGapPeriods(payload);
    },
    getActionOptions(item) {
      const list = [
        {
          id: 'edit',
          value: 'edit',
          label: 'Edit',
          icon: EditIcon,
        }
      ];
      if (item.gap && !item.gap.invalid_gap) {
        list.push({
          id: 'remove',
          value: 'remove',
          label: 'Delete',
          iconClass: 'text-red-500',
          icon: TrashIcon,
          disabled: this.isDisabled,
        });
      }
      return list;
    },
    selectAction(action, item, mobile = true) {
      if (mobile && !this.isMobile) return;
      // console.log('action selected', action, item); // TODO remove
      switch (action) {
        case 'edit':
          this.showEditModal = true;
          this.toggleBlock(item, true);
          break;
        case 'remove':
          if (this.isDisabled) break;
          this.showEditModal = true;
          this.removeTimeline(item);
          break;
      
        default:
          break;
      }
    },
    getGapValue (gap) {
      if (!gap?.field) return null;
      this.getDateFieldValue(gap?.field);
    },
    getDateFieldValue (field) {
      if (!field?.value) return null;
      return JSON.parse(field.value);
    },
    getBlockLabel(entity) {
      const possibleTypes = ['number', 'string'];
      const currentValues = getAllEntitiesByKeyList(entity.entities, {
        block_visibility: true,
        value: (val) => val && possibleTypes.includes(typeof val) && !isJson(val),
      })
        .map((field) => field.value);
      return currentValues.join(', ');
    },
    getDateRangeField(entity) {
      return getEntityByKeyList(entity.entities, {
        field_id: this.gapValidationField.field_fe_id,
      });
    },
    getFromDate(entity) {
      const dateField = this.getDateRangeField(entity);
      const fromDate = dateField?.value ? (JSON.parse(dateField?.value))?.from : null;
      return fromDate ? moment(fromDate).format('DD MMM, YYYY').toString() : fromDate;
    },
    getToDate(entity) {
      const dateField = this.getDateRangeField(entity);
      const toDate = dateField?.value ? (JSON.parse(dateField?.value))?.to : null;
      return toDate ? moment(toDate).format('DD MMM, YYYY').toString() : toDate;
    },
    collapseChanged(id,value){
      this.blockCollapseData[id] = value
    },
    toggleBlock(item, value){
      const alreadyExpanded = !!this.expandedBlocks[item.entity.block_fe_id];
      // use provided value or toggle
      const newValue = value ?? !alreadyExpanded;
      this.$set(this.expandedBlocks, item.entity.block_fe_id, newValue);
      if (!newValue) {
        this.selectedEntity = null;
      } else {
        const isAlreadySelected = this.selectedEntity?.entity.block_fe_id === item.entity.block_fe_id;
        if (!isAlreadySelected) {
          this.selectedEntity = item;
          this.selectedEntityCopy = cloneDeep(item);
        }
      }
      // in case we expande a non-added block the other non-added blocks should be collapsed:
      if (item.gap?.invalid_gap) {
        Object.entries(this.expandedBlocks).forEach(([key, value]) => {
          if (value && key !== item.entity.block_fe_id) {
            const gapEntity = this.sortedEntities.find((el) => el.entity.block_fe_id === key);
            const isInvalid = !!gapEntity?.gap.invalid_gap;
            if (isInvalid) {
              this.$set(this.expandedBlocks, key, false);
            }
          }
        })
      }
    },
    expandFirstInvalidBlock(payload = {}) {
      // console.log('expandFirstInvalidBlock');  // TODO remove
      const { scroll = false, scrollToField = false } = payload;
      const firstInvalid = this.sortedEntities
        .filter((el) => el?.gap?.invalid_gap)[0];

      if (firstInvalid) {
        this.toggleBlock(firstInvalid, true);

        if (scroll) {
          this.$nextTick(() => {
            if (!scrollToField) {
              // select [0] because inside v-for we have a list for each ref:
              const blockNode = this.$refs[firstInvalid.entity.block_fe_id]?.[0];

              if (this.fixedTimeline) this.considerFixedTimelineBlock(blockNode);
              blockNode?.scrollIntoView({ behavior: "smooth",  block: 'start'});
            } else {
              const blockComponent = this.$refs[this.getBlockRef(firstInvalid.entity.block_fe_id)]?.[0];
              this.scrollToInvalidField(blockComponent);
            }
          });
        }
      }
    },
    scrollToInvalidField(blockRef) {
      if (!blockRef?.$refs) return;

      const blockFields = this.getBlockFields(blockRef.$refs)
      if (!blockFields) return;

      const invalidFieldComponent = blockFields.find((el) => !!el.isError);
      const invalidFieldNode = invalidFieldComponent?.$el;

      if (this.fixedTimeline) this.considerFixedTimelineBlock(invalidFieldNode);

      if (invalidFieldNode) {
        return invalidFieldNode.scrollIntoView({ behavior: "smooth",  block: "center" });
      }

      // This method is a workaround to allow scrolling within cascading fields
      this.scrollToTheCascadingInvalidField(blockFields);
    },
    scrollToTheCascadingInvalidField(blockFields) {
      for (const field of blockFields) {
        if (!field?.$el?.childNodes) continue;
  
        const target = field?.$el?.querySelector(CASCADING_FIELD_WITH_ERROR_SELECTOR);

        if (target) {
          target.scrollIntoView(SCROLL_OPTIONS);
          break;
        }
      }
    },
    scrollToModalErrors() {
      const blockComponent = this.$refs.modalBlock;
      this.scrollToInvalidField(blockComponent);
    },
    getBlockFields(refs) {
      return Object.entries(refs)
        .filter(([key]) => key.includes("form-field"))
        .flatMap(([, value]) => value); // pick value
    },
    considerFixedTimelineBlock(targetNode) {
      const timelineNode = this.$refs.gapErrorsRef?.$el;
      if (!targetNode || !timelineNode) return;
      targetNode.style.scrollMarginTop = `${timelineNode.getBoundingClientRect().height}px`;
    },
    resetExpandedBlocks(payload = {}) {
      // console.log('resetExpandedBlocks');  // TODO remove
      const { except = [], scroll = true, scrollToField = false } = payload;

      Object.keys(this.expandedBlocks).forEach((key) => {
        if (!except.some((el) => el.block_fe_id === key)) {
          this.$set(this.expandedBlocks, key, false);
        }
      })
      this.$nextTick(() => {
        this.expandFirstInvalidBlock({ scroll, scrollToField });
      });
    },
    disableScrollToGap(val) {
      this.$emit("disableScrollToGap", val)
    },
    checkCheckboxId(val){
      this.$emit("checkCheckboxId", val)
    },
    emtpyBlockFieldId(val) {
      this.$emit("emtpyBlockFieldId", val)
    },
    checkBlockError(item) {
      return item.validations.$error;
    },
    checkBlockValidation(item, property = '$error') {
      return item.validations[property];
    },
    checkOriginal(el) {
      return el.entity.block_fe_id === this.originalBlock.block_fe_id;
    },
    scrollToGaps() {
      const toScroll = this.isInvalidGap && this.scrollToGap;
      // added this check because this function can be called for the blocks which does not have gap timeline.
      if (toScroll) {
        this.resetExpandedBlocks({ scroll: true, scrollToField: true });
      } 
      return toScroll;
    },
    autofillGaps() {
      // console.log('autofillGaps');  // TODO remove
      // added this check because this function can be called for the blocks which does not have gap timeline.
      if (this.isInvalidGap) {
        const invalidGaps = this.sortedEntities.filter((el) => !!el.gap?.invalid_gap);
        // each gap will be added only if it doesn't have validation errors:
        invalidGaps.forEach((el) => this.fillGap(el, false));
      }
      // if there is still at least one invalid block user should first fill it before recalculation;
      // if there are no more invalid gaps left recalculate if new gaps have appeared
      if (!this.isInvalidGap) {
        this.findGapPeriods(this.gapGroupValues);
      }
      // enable modal auto-open:
      if (this.isInvalidGap) {
        this.showEditModal = true;
        this.resetExpandedBlocks();
      }
      return this.isInvalidGap;
    },
    fillGap(entity, recalculate = true) {
      entity.validations.$touch();
      const isBlockValid = !entity.validations.$invalid;

      if (!isBlockValid) {
        this.$nextTick(() => this.expandFirstInvalidBlock({ scroll: true, scrollToField: true }));
        return;
      }

      this.$set(entity.gap, 'invalid_gap', false);
      this.$set(entity.gap, 'invalid_gap_data', null);
      if (recalculate) {
        this.findGapPeriods(this.gapGroupValues);
        this.resetExpandedBlocks();
      }
    },
    updateTimeline(entity) {
      // if the entity is being changed in modal window cancel timeline updating:
      if (!this.gapGroupValues || entity.gap?.invalid_gap || (this.isModalOpened && this.selectedEntity === entity)) return;
      this.findGapPeriods(this.gapGroupValues);
      // in case of editing through the modal the reset method will be called after modal close
      if (!this.isMobile) this.resetExpandedBlocks({
        except: [entity], // we can exclude the changed gap from the calculation
      });
    },
    removeTimeline(entity) {
      if (!this.gapGroupValues) return;
      if (entity && this.checkOriginal(entity)) {
        // reset entity gap data if there is no data (gap has been removed):
        if (!entity.gap) {
          this.$set(entity, 'gap', {
            field: this.getDateRangeField(entity.entity),
            block: entity.entity,
            invalid_gap: true,
          });
        }
        this.$set(entity.gap, 'invalid_gap', true);
        this.$set(entity.gap, 'invalid_gap_data', {});
        resetValues(entity.entity);
      } else {
        if (entity.gap?.block) mutations.removeFilledGap(entity.entity);
        this.utils.removeEntity({
          entity: entity.entity,
          list: this.section.entities,
          is_saved: !entity.entity.is_copy, // is_copy is a frontend value that mean that block hasn't been saved yet
          form_entity_type: entity.entity.form_entity_type,
        });
      }
      this.showEditModal = true;
      this.findGapPeriods(this.gapGroupValues);
      this.resetExpandedBlocks();
    },
    // TODO DEL-8300 check if we need this method here:
    removeLinkedTimeline(blockData) {
      // console.warn('removeLinkedTimeline') // TODO remove
      if (!this.store.gap_values[blockData.block_id]) return;
      const gapToRemove = this.store.gap_values[blockData.block_id].timeline.find(
        (el) => el.block?.block_fe_id === blockData.block_fe_id
      );
      // reset gaps list if there is gap to remove:
      if (gapToRemove)
        this.store.gap_values[blockData.block_id].timeline = this.store.gap_values[blockData.block_id].timeline.filter(
          (el) => el.block?.block_fe_id !== blockData.block_fe_id
        );
    },
    selectGap(payload) {
      // console.log('selectGap payload', payload); // TODO remove
      if (!payload) {
        this.$toast.error("Cannot fill a gap. The maximum period count has already been reached");
        return;
      }
      // find the related entity:
      const blockId = payload.block.block_fe_id;
      // 2nd condition: if it is linked block find necessary block by link:
      const gapEntity = this.sortedEntities.find((el) => el.entity.block_fe_id === blockId || el.entity.use_value_from === blockId);
      // console.log('selectGap gapEntity', gapEntity); // TODO remove
      if (!gapEntity) return;
      this.showEditModal = true;
      this.toggleBlock(gapEntity, true);
      // select [0] because inside v-for we have a list for each ref:
      const blockNode = this.$refs[gapEntity.entity.block_fe_id]?.[0];
      // console.log('selectGap blockNode', blockNode); // TODO remove
      if (this.fixedTimeline) this.considerFixedTimelineBlock(blockNode);
      blockNode?.scrollIntoView({ behavior: "smooth" });
    },
    closeModal() {
      // console.log('closeModal'); // TODO remove
      this.selectedEntity = null;
      this.selectedEntityCopy = null;
    },
    saveFromModal() {
      // console.log('saveFromModal'); // TODO remove
      if (this.toResetExpanded) {
        this.$nextTick(() => {
          this.resetExpandedBlocks();
          this.toResetExpanded = false;
        });
      }
    },
    fillFromModal(payload) {
      // console.log('fillFromModal'); // TODO remove
      this.fillGap(payload);
    },
    cancelModal() {
      // console.log('cancelModal'); // TODO remove
      // reset values to previous if cancelled
      // TODO check why modal is being closed twice:
      if (!(this.selectedEntity && this.selectedEntityCopy)) return;
      useValuesFrom(this.selectedEntity.entity, this.selectedEntityCopy.entity);
      this.toResetExpanded = false;
      this.showEditModal = false;
    },
    updateFromModal() {
      this.toResetExpanded = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.bg-error {
  background-color: #ff00000a;
}

.gv-block {
  &__header {
    display: flex;
    gap: 12px;
  }
  &__body {
    width: 100%;
  }
  &__block-controls {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
  }
}

.header-col {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1 1 30%;
  &__label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__value {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    word-break: break-word;
  }
  &.gap-actionItems {
    flex: 1 0 10%;
  }
}

.gap-actionItems {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  &__container {
    align-self: stretch;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;;
    gap: 12px;
    flex: 1 1 70%;
  }
  &__buttons {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    flex: 1 0 30%;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<style lang="scss">
</style>