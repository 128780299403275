var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShown),expression:"isShown"}],staticClass:"gvgroup rounded-xl border-2 bg-white",class:{
    'border-blue-400': !(_vm.isError || _vm.isInvalidGap),
    'border-red-600': _vm.isError || _vm.isInvalidGap,
  }},[(_vm.isShown)?[_c('portal',{attrs:{"to":`${_vm.originalBlock.block_fe_id}_name${_vm.previewForm ? '-preview' : ''}`}},[(_vm.originalBlock.block_name)?_c('h3',{staticClass:"text-lg capitalize font-semibold text-base-content mb-2 blockNameLable"},[_vm._v(" "+_vm._s(_vm.originalBlock.block_label)+" ")]):_vm._e()]),_c('portal',{attrs:{"to":`${_vm.originalBlock.block_fe_id}_gaps${_vm.previewForm ? '-preview' : ''}`}},[_c('GapErrors',{key:_vm.gapErrors.id,ref:"gapErrorsRef",staticClass:"block-gaps mb-4",class:{'form-field--invalid': _vm.gapErrorMsg === 'ERROR.GAP_VALIDATION'},attrs:{"message":_vm.gapErrors.message,"gapGroup":_vm.timelineData,"previewForm":_vm.previewForm,"blockData":_vm.originalBlock,"formAlreadySubmitted":_vm.formAlreadySubmitted,"modalEdit":_vm.modalEdit,"isError":_vm.isError || _vm.isInvalidGap},on:{"select:gap":_vm.selectGap,"reload":_vm.scrollToGaps}})],1)]:_vm._e(),_c('h3',{staticClass:"gvgroup__title py-5 px-7 rounded-t-xl font-semibold border-b border-line",class:{
      'border-red-600 bg-error': _vm.isError || _vm.isInvalidGap,
      'bg-blue-100': !(_vm.isError || _vm.isInvalidGap),
    }},[_vm._v(" "+_vm._s(_vm.groupTitle)+" ")]),_c('div',{staticClass:"gvgroup__list rounded-b-xl w-full"},_vm._l((_vm.sortedEntities),function(el,posInGroup){return _c('div',{key:el.entity.block_fe_id,ref:el.entity.block_fe_id,refInFor:true,staticClass:"gv-block",class:{
        'border-b border-line': !(posInGroup === _vm.sortedEntities.length - 1),
        'cursor-pointer': _vm.isMobile,
      },on:{"click":function($event){return _vm.selectAction('edit', el)}}},[_c('div',{staticClass:"gv-block__header px-7 py-5",class:{
          'text-blue-600': !(el.gap?.invalid_gap || _vm.checkBlockValidation(el)),
          'bg-error': el.gap?.invalid_gap,
          'text-red-600': el.gap?.invalid_gap || _vm.checkBlockValidation(el),
        }},[_c('div',{staticClass:"gap-title header-col"},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              content: _vm.groupTitle,
              placement: 'bottom'
            }),expression:"{\n              content: groupTitle,\n              placement: 'bottom'\n            }"}],staticClass:"header-col__label font-medium"},[_vm._v(" "+_vm._s(_vm.groupTitle)+" ")]),_c('div',{staticClass:"header-col__value text-base-content"},[_vm._v(" "+_vm._s(_vm.getBlockLabel(el.entity) || '--')+" ")])]),_c('div',{staticClass:"gap-fromDate header-col"},[_c('div',{staticClass:"header-col__label font-medium"},[_vm._v(" From ")]),_c('div',{staticClass:"header-col__value text-base-content"},[_vm._v(" "+_vm._s(_vm.getFromDate(el.entity) || '--')+" ")])]),_c('div',{staticClass:"gap-toDate header-col"},[_c('div',{staticClass:"header-col__label font-medium"},[_vm._v(" To ")]),_c('div',{staticClass:"header-col__value text-base-content"},[_vm._v(" "+_vm._s(_vm.getToDate(el.entity) || '--')+" ")])]),_c('div',{staticClass:"gap-actionItems header-col text-right"},[_c('div',{staticClass:"gap-actionItems__container"},[_c('div',{staticClass:"header-col__label font-medium gap-actionItems__label"},[_vm._v(" Action ")]),_c('div',{staticClass:"gap-actionItems__buttons text-base-content"},[(!_vm.isMobile)?[_c('EditButton',{class:{
                    'text-blue-600': !(el.gap?.invalid_gap || _vm.checkBlockValidation(el)),
                    'text-red-600': el.gap?.invalid_gap || _vm.checkBlockValidation(el),
                  },attrs:{"disabled":_vm.isDisabled},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggleBlock(el, true)}}},[_vm._v("Edit")]),(!el.gap?.invalid_gap)?_c('RemoveButton',{attrs:{"disabled":_vm.isDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.removeTimeline(el)}}}):_vm._e()]:_c('DropdownMenu',{class:{
                  'text-red-600': el.gap?.invalid_gap || _vm.checkBlockValidation(el),
                  'text-blue-600': !(el.gap?.invalid_gap || _vm.checkBlockValidation(el)),
                },attrs:{"right":true,"options":_vm.getActionOptions(el)},on:{"select":function($event){return _vm.selectAction($event, el)}},nativeOn:{"click":function($event){$event.stopPropagation();}}})],2)]),(!_vm.isMobile)?_c('div',{staticClass:"gap-actionItems__icon"},[_c('ToggleButton',{attrs:{"expanded":_vm.expandedBlocks[el.entity.block_fe_id]},nativeOn:{"click":function($event){return _vm.toggleBlock(el)}}})],1):_vm._e()])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandedBlocks[el.entity.block_fe_id] && !_vm.isMobile),expression:"expandedBlocks[el.entity.block_fe_id] && !isMobile"}],staticClass:"gv-block__block-container pb-5"},[_c('div',{staticClass:"px-7 gv-block__block",attrs:{"colspan":"4"}},[_c('Block',{key:!!el.gap?.invalid_gap,ref:_vm.getBlockRef(el.entity.block_fe_id),refInFor:true,class:{'blockCollaps': posInGroup === 0, dynamicBlock: _vm.previewForm},attrs:{"blockData":el.entity,"section":_vm.section,"path":[
              ..._vm.path,
              { field: 'block_fe_id', value: el.entity.block_fe_id },
            ],"isMulti":_vm.checkIfMulti(el.entity, _vm.entities),"validations":el.validations?.entities || null,"parentValidations":_vm.validations,"previewMode":_vm.previewMode,"previewForm":_vm.previewForm,"grouped":_vm.showGroup,"gapValidationGroup":true,"posInGroup":posInGroup,"scrollToGap":_vm.scrollToGap,"gapErrorMsg":_vm.gapErrorMsg,"formAlreadySubmitted":_vm.formAlreadySubmitted,"checkBoxId":_vm.checkBoxId,"hidden":!(_vm.expandedBlocks[el.entity.block_fe_id] && !_vm.isMobile)},on:{"disableScrollToGap":_vm.disableScrollToGap,"checkCheckboxId":_vm.checkCheckboxId,"emtpyBlockFieldId":_vm.emtpyBlockFieldId,"addTimeline":function($event){return _vm.updateTimeline(el)}}})],1),(el.gap?.invalid_gap)?_c('div',{staticClass:"gv-block__block-controls px-7"},[_c('AddButton',{attrs:{"disabled":!_vm.isMulti || !_vm.isSource},on:{"click":function($event){return _vm.fillGap(el)}}})],1):_vm._e()])])}),0),(_vm.isModalOpened)?_c('EditBlockModal',{attrs:{"toAdd":_vm.selectedEntity.gap?.invalid_gap,"item":_vm.selectedEntity,"isPeriodProvided":_vm.isPeriodProvided,"isMulti":_vm.isMulti,"disabled":!_vm.isSource},on:{"add":function($event){return _vm.fillFromModal(_vm.selectedEntity)},"save":_vm.saveFromModal,"close":_vm.closeModal,"cancel":_vm.cancelModal,"error":_vm.scrollToModalErrors}},[_c('Block',{ref:"modalBlock",class:{'blockCollaps': true, dynamicBlock: _vm.previewForm},attrs:{"blockData":_vm.selectedEntity.entity,"section":_vm.section,"path":[
        ..._vm.path,
        { field: 'block_fe_id', value: _vm.selectedEntity.entity.block_fe_id },
      ],"isMulti":_vm.checkIfMulti(_vm.selectedEntity.entity, _vm.entities),"validations":_vm.selectedEntity.validations?.entities || null,"parentValidations":_vm.validations,"previewMode":_vm.previewMode,"previewForm":_vm.previewForm,"grouped":_vm.showGroup,"gapValidationGroup":true,"posInGroup":0,"scrollToGap":_vm.scrollToGap,"gapErrorMsg":_vm.gapErrorMsg,"formAlreadySubmitted":_vm.formAlreadySubmitted,"checkBoxId":_vm.checkBoxId},on:{"disableScrollToGap":_vm.disableScrollToGap,"checkCheckboxId":_vm.checkCheckboxId,"emtpyBlockFieldId":_vm.emtpyBlockFieldId,"addTimeline":_vm.updateFromModal}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }