// const sections =

import FormBuilder from "./components/dynamicFormBuilder.vue";
import { EventBus } from "@/main.js";
import { /* get, set, */ cloneDeep } from "lodash";
// import { generateRandomString } from "@/utils/functions.js";
import { helpers, requiredIf } from "vuelidate/lib/validators";
// fakeData for testing:
// import { fakeData1, fakeData2 } from "./utils/fakeData.js";
import sections, { mutations } from "./store";
import fieldTypes from "./utils/field-types";
import {
    getEntityByKeyList,
    isJson,
    logValidationErrors,
    subtractMonths,
    formatDate,
    getFieldById,
    removeLocalStorageItemsWithPrefix,
    isEquel,
    getCascadingFields,
    getAllEntitiesByKeyList,
    useValuesFrom,
    getDateString,
    getDateFieldValue,
    sectionStatusConfig,
} from "./utils";
import UserProfile from "./components/form-components/user-profile";
import PoweredByIcon from "@shared/assets/powered-by.svg";
import PrintIcon from "@shared/assets/print.svg";
import previewFormModal from "./components/previewFormModal.vue";
import Loader from "@shared/loader";
import Vue from "vue";
import Toast from "vue-toastification";
import {checkPermission} from "@shared/utils/functions"
import "vue-toastification/dist/index.css";
Vue.use(Toast);

import { uuid } from "vue-uuid";
import {
    REGULAR_FIELD_WITH_ERROR_SELECTOR,
    CASCADING_FIELD_WITH_ERROR_SELECTOR,
    SCROLL_OPTIONS,
} from "./constants";

const customValidators = {
    regex: (regexString) => (val) => new RegExp(regexString).test(val),
};

// const formateDateToString= (date) =>{
//     const year = date.getUTCFullYear();
//     const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
//     const day = date.getUTCDate().toString().padStart(2, '0');
//     const hours = date.getUTCHours().toString().padStart(2, '0');
//     const minutes = date.getUTCMinutes().toString().padStart(2, '0');
//     const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  
//     return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
// }

// Use it if need to control whether form was saved on the server side:
const formSavedErrorCodes = ["ERROR.GAP_VALIDATION"];

export default {
    name: "neo-form-builder",
    components: {
        FormBuilder,
        UserProfile,
        PoweredByIcon,
        PrintIcon,
        previewFormModal,
        Loader,
    },
    props: {
        app: String,
        data: {
            type: Object,
            requiered: true,
        },
        saveSectionMethod: {
            type: Function,
        },
        // TODO remove when custom sections are ready:
        // savePersonalDetailsMethod: {
        //     type: Function,
        // },
        removeBlockMethod: {
            type: Function,
        },
        removeFieldMethod: {
            type: Function,
        },
        // is used to preview form before creating it in an admin panel:
        previewMode: {
            type: Boolean,
            default: false,
        },
        gapErrors: {
            type: Object,
            default: () => {},
        },
        userProfileView: {
            type: Boolean,
            default: false,
        },
        userInfo: {
            type: Object,
            default: () => {},
        },
        // TODO remove when custom sections are ready:
        // showWelcomeSection: {
        //     type: Boolean,
        //     default: false,
        // },
        // showPersonalDetailsSection: {
        //     type: Boolean,
        //     default: false,
        // },
        printableSections: {
            type: Boolean,
            default: false,
        },
        case_id: String,
        formStates: {
            type: Object,
            default: () => ({ loading: false, submitted: false }),
        },
        isCandidate: Boolean,
        gapErrorMsg: String,
        permissionEnforced: {
            type: String,
            default: ''
        },
        formAlreadySubmitted: {
            type: Boolean,
        },
        isAddReadOnly:{
            type: Boolean,
        },
        userData: {
            type: Object
        }
    },
    data() {
        return {
            // sections_data: [],
            selectedSectionId: null,
            // isConsent: false,
            sections_to_save: {}, // * uncomment if need to check if section has been changed,
            current_copy: "",
            formBuilderId: "",
            saveDisabled:false,
            isDivVisible: false,
            selectedSectionIndex: null, // Variable to store the current index
            hideErrors: false,
            finalSubmit:false,
            allDuplicateFields: {},
            btnClicked: true ,     // true=>nextClicked false=>prevClicked
            sectionDivHeight: '',
            isError: false,
            isChromeOniPhone: "",
            chromeStyles: {
            },
            findErrors:{
                isSaved:false,
                currentIndex:null
            },
            isPoweredByVisible: false,
            stopCancel:false,
            sectionAnyError:false,
            modelConsentList: [],
            // the list of entities that have been selected to be removed;
            // request to API for removal will be sent on section save/next 
            entitiesToRemove: [],
        }
    },
    validations() {
        const sections_data = {
            ...this.sections_data.map((el) => {
                return this.setValidations(el);
            }),
        };
        // check if there is any invalid_gap in the current section:
        const gap_values = Object.keys(this.currentGapGroups).reduce((result, key) => {
            result[key] = {}
            result[key].timeline = (value) => {
                return !value.timeline?.some((gap) => gap.invalid_gap);
            }
            return result;
        }, {});
        // TODO remove when custom sections are ready:
        // const personal_details = this.setPersonalValidations(this.getPersonalDetails);
        // if (personal_details !== null)
        //     return {sections_data, personal_details};
        const resultValidation = { sections_data, gap_values };
        // const resultValidation = { sections_data };
        return resultValidation;
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowResize);
        this.getWindowSize();

        this.updateStyles();
        window.addEventListener('resize', this.updateStyles);
        window.addEventListener('orientationchange', this.updateStyles);
        //! STARTING GAP VALIDATION DEFAULT
        // let gaps = [
        //     {
        //         "block_id": "5ac3f8e1-b913-4a35-9370-d7e83bff489c",
        //         "block_name": "DEL-2623 Gap Timeline Absolute",
        //         "field_id": "1a18e3da-bc52-458d-ae9f-dca5f9e36734",
        //         "field_order": 1,
        //         "timeline": [
        //             {
        //                 "field_id": "1a18e3da-bc52-458d-ae9f-dca5f9e36734",
        //                 "start": "2021-05-01 00:00:00",
        //                 "end": "2021-05-31 00:00:00",
        //                 "current": false,
        //                 "block_fe_id": "5ac3f8e1-b913-4a35-9370-d7e83bff489c",
        //                 "invalid_gap": false
        //             },
        //         ],
        //         "overlaps": []
        //     },
        //     {
        //         "block_id": "10797d44-c60b-4a24-91d0-02ec53b93139",
        //         "block_name": "DEL-2623 Gap Timeline Relative",
        //         "field_id": "1fdd30ed-2d40-48e1-b8f9-aa6c6087f512",
        //         "field_order": 4,
        //         "timeline": [
            //             {
        //                 "field_id": "1fdd30ed-2d40-48e1-b8f9-aa6c6087f512",
        //                 "start": "2020-05-01 00:00:00",
        //                 "end": "2020-06-30 00:00:00",
        //                 "current": false,
        //                 "block_fe_id": "10797d44-c60b-4a24-91d0-02ec53b93139",
        //                 "invalid_gap": false
        //             },
        //         ],
        //         "overlaps": []
        //     },
            // {
            //     "block_id": "05e3aa59-36a9-47d8-bfa1-cb0aad4ad7af",
            //     "block_name": "DEL-2623 Gap Timeline Relative Till Present",
            //     "field_id": "f46cae9e-4685-4aad-aacf-ebe7a5eee521",
            //     "field_order": 5,
            //     "timeline": [
            //         {
            //             "field_id": "f46cae9e-4685-4aad-aacf-ebe7a5eee521",
            //             "start": "2021-05-19 00:00:00",
            //             "end": "2021-07-30 00:00:00",
            //             "current": false,
            //             "block_fe_id": "05e3aa59-36a9-47d8-bfa1-cb0aad4ad7af",
            //             "invalid_gap": false
            //         },
            //     ],
            //     "overlaps": []
            // }
        // ]
        // this.gap_errors = {
        //     id: uuid.v4(),
        //     gaps,
        //     message: 'Hello world',
        //     gapErrorsView: true
        // };
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    },
    created() {
        window.addEventListener('orientationchange', this.calculateHeight);
        window.addEventListener('resize', this.calculateHeight);
        this.sections_data = this.data.sections.map((el) => this.organizeFieldsData(el));
        // we should call formbuilder reload on every sections_data change:
        this.reloadFormBuilder();
        EventBus.$on("nextSection", this.nextSection);
        // TODO remove when custom sections are ready:
        // EventBus.$on("changePersonalFieldValue", (data) => {
        //     this.handleChangePersonalValue(data);
        // });

        // this.sections_data = this.data.sections;
        sections.utils.copyEntity = this.copyEntity;
        sections.utils.removeEntity = this.removeEntity;
        sections.utils.findGapPeriods = this.findGapPeriods;
        sections.utils.copyEntityWithoutSection = this.copyEntityWithoutSection
        sections.utils.handleValues = this.handleValues
        sections.utils.saveGapModalBlock = this.saveGapModalBlock;
        sections.utils.generateGapErrors = this.generateGapErrors;
        
        // TODO DEL-8300 check; should be handled in a new way
        // this.gap_errors = {
        //     ...this.gapErrors,
        //     id: uuid.v4(),
        // };

        // Provide global variable for the current case id:
        this.caseId = this.case_id;

        // select active section
        if (this.sections_data.length > 0) {
            // select depending on data from API or first by default^
            const currIndex = this.data.active_section || 0;
            // this.selectedSectionId = this.sections_data[currIndex].section_id
            this.selectedSectionId = this.getSections[currIndex].id;

            // this.sections_data.map(section => {
            //    if (section.entities.length > 0) {
            //        section.entities = section.entities.map(entity =>  ({...entity, _id: this.generateRandomString(true)}) )
            //    }
            //    return section
            // })
        }
    },
    beforeDestroy() {
        EventBus.$off("nextSection");
        removeLocalStorageItemsWithPrefix('gapTimeline_')

        window.removeEventListener('resize', this.updateStyles);
        window.removeEventListener('orientationchange', this.updateStyles);
        // TODO remove when custom sections are ready:
        // EventBus.$off("changePersonalFieldValue");
    },
    destroyed() {
        window.removeEventListener('orientationchange', this.calculateHeight);
        window.removeEventListener('resize', this.calculateHeight);
      },
    computed: {
        currentSection() {
          return this.sections_data[this.getSelectedSectionIndex];
        },
        currentGapGroups() {
            return Object.entries(this.gap_values).reduce((result, [key, value]) => {
                const isInCurrentSection = getEntityByKeyList(this.getSelectedSection.entities, {
                    block_fe_id: key,
                });
                if (isInCurrentSection) {
                    result[key] = value
                }
                return result;
            }, {});
        },
        dependencies() {
            return sections.dependencies;
        },
        windowWidth: {
            get() {
                return sections.windowWidth;
            },
            set(val) {
                sections.windowWidth = val;
                sections.isMobile = val < sections.mobileWidth;
            },
        },
        sections_data: {
            get() {
                return sections.list;
            },
            set(val) {
                sections.list = val;
            },
        },
        personal_details: {
            get() {
                return sections.personal_details;
            },
            set(val) {
                sections.personal_details = val;
            },
        },
        gap_errors: {
            get() {
                return sections.gap_errors;
            },
            set(val) {
                // sections.gap_errors = val || {};
                mutations.handleGapErrors(val);
            },
        },
        gap_values: {
            get() {
                return sections.gap_values;
            },
            set(val) {
                sections.gap_values = val || {};
            },
        },
        // TODO remove when custom sections are ready:
        // welcomeSection() {
        //     if (!this.showWelcomeSection || !this.data.welcome_screen_content) return null;
        //     return {
        //         section_id: 'welcome_section',
        //         type: 'welcome',
        //         content: this.data.welcome_screen_content,
        //         custom: true,
        //         section_name: 'Welcome',
        //         section_order: -2,
        //     }
        // },
        // personalDetailsSection() {
        //     if (!this.showPersonalDetailsSection || !this.data.candidate) return null;
        //     return {
        //         section_id: 'personal_details_section',
        //         type: 'personal_details',
        //         data: this.getPersonalDetails,
        //         custom: true,
        //         section_name: 'Personal Details',
        //         section_order: -1,
        //     }
        // },
        // changeCustomSection() {
        //     return {
        //         'personal_details_section': async () => {
        //             this.$v.personal_details.$touch();
        //             if (this.$v.personal_details.$invalid) return false;
        //             if (this.savePersonalDetailsMethod) {
        //                 await this.savePersonalDetailsMethod(this.personal_details);
        //             }
        //             return true;
        //         }
        //     }
        // },
        // getPersonalDetails() {

        //     if (!this.showPersonalDetailsSection){
        //         return null
        //     }
        //   return this.organizePersonalDetails(this.personal_details);
        // },
        caseId: {
            get() {
                return sections.case_id;
            },
            set(val) {
                sections.case_id = val || "";
            },
        },
        linked_blocks: {
            get() {
                return sections.linked_blocks;
            },
            set(val) {
                sections.linked_blocks = val || [];
            },
        },
        getFilteredSections(){
            return this.sections_data.filter((sec) => !sec.sectionHide).map((sec) => ({ id: sec.section_id, name: sec.section_name, order: sec.section_order, has_error: sec.has_error, status: sec?.status, candidate_status: sec?.candidate_status ? sec?.candidate_status : sec?.status, $v: sec.$v || {} })).sort((a, b) => b.section_order - a.section_order);
        },
        getSections() {
            // if(this.isCandidate) { mutations.markDuplicateFields(); }
            return this.sections_data.map((sec) => ({ id: sec.section_id, name: sec.section_name, order: sec.section_order, has_error: sec.has_error, status: sec?.status, candidate_status: sec?.candidate_status ? sec?.candidate_status : sec?.status, $v: sec.$v || {} , sectionHide: sec?.sectionHide })).sort((a, b) => b.section_order - a.section_order);
            // TODO remove when custom sections are ready:
            // return this.sectionDataExtended
            //     .map(sec => ({ id: sec.section_id, name: sec.section_name, order: sec.section_order, has_error: sec.has_error, custom: !!sec.custom, $v: sec.$v || {} }))
            //     .sort((a, b) => (b.section_order - a.section_order));
        },
        // TODO remove when custom sections are ready:
        // includes 'welcome' and 'personal details' sections:
        // sectionDataExtended() {
        //     const sections_data = this.sections_data.map((sec, idx) => ({
        //         ...sec,
        //         $v: this.$v.sections_data[idx],
        //     }));
        //     return [this.welcomeSection, this.personalDetailsSection, ...sections_data]
        //         .filter(section => section);
        // },
        getSelectedSection() {
            // return this.sections_data[0] NOTE(fix)
          
            return this.sections_data.length > 0 && this.selectedSectionId ? this.sections_data.find((sec) => sec.section_id === this.selectedSectionId) : {};
            // TODO remove when custom sections are ready:
            // return (this.sectionDataExtended.length > 0 && this.selectedSectionId) ? this.sectionDataExtended.find(sec => sec.section_id === this.selectedSectionId) : {}
        },
        getSelectedSectionIndex() {
            return this.sections_data.length > 0 && this.selectedSectionId ? this.sections_data.findIndex((sec) => sec.section_id === this.selectedSectionId) : 0;
        },
        // TODO remove when custom sections are ready:
        // includes 'welcome' and 'personal details' sections:
        // getSelectedSectionIndexExtended() {
        //     return (this.getSections.length > 0 && this.selectedSectionId) ? this.getSections.findIndex(sec => sec.id === this.selectedSectionId) : 0;
        // },
        errorFromServer() {
            // TODO
            return !!this.sections_data.find((el) => !!el.has_error);
        },
        getCurrentValidations() {
            return this.$v.sections_data[this.getSelectedSectionIndex];
        },
        // this validation takes into account only gap validation groups in the current section
        getGapValidations() {
            return this.$v.gap_values;
        },
        // getPersonalValidations() {
        //     return this.$v.personal_details;
        // },
        getSectionsHeaderTitle() {
            var title = "Form preview";
            if (!this.previewMode) {
                title = "Sections";
            }
            return title;
        },
        consentField() {
            //  reset the consent list
            this.modelConsentList = [];
            // get all the consent fields
            const visibleConsentField = this.getEntityByKeyListConsent(this.getSelectedSection.entities, {
                field_type: "Candidate Consent",
            });
            return visibleConsentField;
        },
        isConsent() {
            const values = this.consentField && this.consentField.length > 0
                ? this.consentField
                    .filter((el) => el.mandatory)
                    .map((el) => el.value)
                : [];
            const allConsentAgree = values?.length ? values.every(this.checkAllMandatoryConsent) : true;
            return allConsentAgree;
        },
        // isConsentInCurrentSection() {
        //     return !!getEntityByKeyList(this.getSelectedSection.entities, {
        //         field_base_type: 'Consent',
        //     })
        // },
        isConsentPassed() {
            const passed = this.consentField && !this.consentField.isDuplicate ? this.isConsent : true;
            if(passed) {
                this.isError = false;
            }
            return passed
        },
        isSubmitDisabled() {
            const { submitted, loading } = this.formStates;
            return submitted || loading || this.$v.$invalid || !this.isConsentPassed;
        },
        getRefereeUserData() {
            return this.userData;
        },
        sectionEnvStatusProp() {
            return sectionStatusConfig.getStatusProperty(this.app);
        },
    },
    watch: {
        data(val) {
            this.sections_data = val.sections.map((el) => this.organizeFieldsData(el));
            this.reloadFormBuilder();
        },
        // TODO DEL-8300 check; should be handled in a new way
        // update gap errors
        // gapErrors(val) {
        //     this.gap_errors = {
        //         ...val,
        //         id: uuid.v4(),
        //     };
        //     // reset saved timelines:
        //     // this.gap_values = {}; // TODO
        // },
        case_id(val) {
            this.caseId = val;
        },
    },
    methods: {
        firstBlockVisibleError() {
            let showFirstBlockError = false;
            let gapBlocks = this.getSelectedSection?.entities?.filter(el => el.showFirstBlock != undefined)
            if(!gapBlocks?.length) {
                showFirstBlockError = false;
            } else {
                if (gapBlocks?.every(el => el.showFirstBlock)) {
                    showFirstBlockError = false;
                } else {
                    showFirstBlockError = true;
                }
            }
            return showFirstBlockError;
        },
        updateStyles() {
            this.isChromeOniPhone = /CriOS/.test(navigator.userAgent) && /iPhone/.test(navigator.userAgent);
            this.chromeStyles.top = this.isChromeOniPhone ? '160px' : 'auto';
          },
        // Do we need this blockhasErrors() method? May we use Vuelidate validation that has already been implemented on the project instead?
        blockhasErrors() {
            this.getSelectedSection?.entities?.forEach(secEnty => {
                secEnty?.entities?.map(elEnty => {
                    if(elEnty?.visible && !elEnty?.render_conditionally && elEnty?.mandatory) {
                        if (elEnty?.value == null || elEnty?.value == '' || elEnty?.value == undefined) {
                            elEnty.inValidBlock = true;
                        } else {
                            elEnty.inValidBlock = false;
                        }
                    } else if (elEnty?.render_conditionally) {
                        let parentField = this.blockData?.entities?.find(el => el?.field_id == elEnty?.condition?.field_id)
                        if (parentField?.value == elEnty?.condition?.dependence_value) {
                          if (elEnty?.value == '' || elEnty?.value == null) {
                            elEnty.ui_error = true;
                            elEnty.ui_error_msg = 'This field is required!';
                          } else {
                            elEnty.ui_error = false;
                            elEnty.ui_error_msg = '';
                          }
                        } else {
                          elEnty.ui_error = false;
                          elEnty.ui_error_msg = '';
                        }
                      }
                }); 
            });
        },
        async changeHiddenSection(){
            this.btnClicked ? await this.changeSection(1,true): await this.prevSection() ;
        },
        checkPermission,
        reloadFormBuilder() {
            this.formBuilderId = uuid.v4();
            this.personal_details = this.data.candidate || this.userInfo;
            this.linked_blocks = this.data.linked_blocks;
            this.generateGapErrors();
            this.resetLinkedBlocks();
        },
        toggleDiv() {
            this.isDivVisible = !this.isDivVisible;
            this.calculateHeight();
        },
        calculateHeight() {
            const { height } = this.$el.getBoundingClientRect();
            const mediaQuery = window.matchMedia('(orientation: portrait)');
            if (mediaQuery.matches) {
                this.sectionDivHeight = (height-160)+'px'
            } else {
                this.sectionDivHeight = (height-160)+'px'
            }
        },
        onDragStart(event) {
            event.dataTransfer.setData('text/plain', ''); // Required for drag-and-drop
        },
       
         
          
        // setValidations(data, values = {}) {
        //     if (data.entities) {
        //         return {
        //             entities: data.entities.map(el => this.setValidations(el, values)),
        //         }
        //     }
        //     else if (data.form_entity_type === "FIELD") {
        //         const result = {}
        //         // if (data.mandatory) result.required = required;
        //         return { ...values, ...result }
        //     }
        // },

        // Init 'value' prop for fields if not exist
        // path is used to make an unique field identificator
        organizeFieldsData(data, resetData = {}, initValueKey = "value", path = [], index = 0) {
            if (data.entities) {
                const additional = {};
                const newPath = [...path];
                if (data.form_entity_type === "BLOCK") {
                    additional.block_fe_id = data.block_fe_id || data.block_id;
                    additional.serial = data.serial ?? 1;
                    additional.order_serial = data.serial ?? 1;
                    newPath.push(additional.block_fe_id);
                } else if (data.section_id) newPath.push(data.section_id);
                return {
                    ...data,
                    ...additional,
                    ...resetData.block,
                    entities: data.entities
                        // TODO remove filter, added for skipping unknow types
                        .filter((entity) => {
                            return !(entity.form_entity_type === "FIELD" && !fieldTypes[entity.field_type.toLowerCase()] && !fieldTypes[entity.field_base_type.toLowerCase()]);
                        })
                        .map((el, index) => this.organizeFieldsData(el, resetData, initValueKey, newPath, index)),
                };
            } else if (data.form_entity_type === "FIELD") {
                const additional = {};
                additional.field_fe_id = data.field_fe_id || data.field_id;

                additional.field_serial = data.field_serial ?? 1;
                const newPath = [...path];
                newPath.push(additional.field_fe_id);
                const pathString = newPath.join("/");
                const pathParent = [...path];
                const pathParentString = path.join("/");
                if (data.render_conditionally && data.condition) {
                    this.$set(this.dependencies, pathString, { condition: data.condition });
                }
                // actualize date fields' values with current: true
                if (data.field_base_type === 'Date Range') {
                    const fieldValue = getDateFieldValue(data);
                    if (fieldValue?.current) {
                        const newValue = JSON.stringify({
                           ...fieldValue,
                           to: getDateString(new Date()), 
                        });
                        data.value = newValue;
                    }
                }

                // added index key for dependent fields to look up values
                return {
                    [initValueKey]: "",
                    ...data,
                    ...additional,
                    ...resetData.field,
                    path: newPath,
                    pathString,
                    pathParent,
                    pathParentString,
                    index
                };
            }
        },
        // Method for resettings entities' properies with saving links to entities:
        resetFieldsData(data, resetData = {}) {
            if (data.form_entity_type === "BLOCK") {
                return Object.assign(data, resetData.block, {
                    entities: data.entities.map((el) => this.resetFieldsData(el, resetData)),
                });
            } else if (data.form_entity_type === "FIELD") {
                return Object.assign(data, resetData.field);
            } else {
                // sections
                return Object.assign(data, {
                    entities: data.entities.map((el) => this.resetFieldsData(el, resetData)),
                });
            }
        },
        // Set valdiation for fields, 
        setValidations(data, values = {}) {
            if (data.entities) {
                return {
                    entities: { ...data.entities.map((el) => this.setValidations(el, values)) },
                };
            } else if (data.form_entity_type === "FIELD") {
                const fieldType = fieldTypes[data.field_type.toLowerCase()] || fieldTypes[data.field_base_type.toLowerCase()];
                const result = {
                    ...values,
                    ...fieldType.validators,
                };
                result.requiredIf = helpers.withParams(
                    { $message: `This field is required` },
                    requiredIf(() => {
                        return data.mandatory && !this.dependencies[data.pathString]?.hidden && !fieldType.skipValidation && data.visible && !data.isDuplicate && !data.use_value_from;
                    })
                );
                if (data.field_type.toUpperCase()==='CANDIDATE PHONE' && data.mandatory && !data.isDuplicate) data.field_validation_regex = /^\+\d{1,5}\d{8,}$/
                if (data.field_validation_regex) result.regex = helpers.withParams({ $message: `Invalid field value` }, customValidators.regex(data.field_validation_regex));

                const resultValidations = {
                    value: result
                }

                // validations for cascading fields:
                const cascadingFields = getCascadingFields(data)
                if (cascadingFields) {
                    resultValidations.options = {
                        ...data.options.map((option) => {
                            const isSelected = option.option_value === data.value
                            return {
                                // validate only current visible cascading fields:
                                cascading_fields: isSelected && !data.use_value_from
                                    ? { ...option.cascading_fields.map((el) => this.setValidations(el, values)) }
                                    : {}
                            }
                        })
                    }
                }


                return resultValidations;
            }
        },
        checkIfCascaded(data) {
            return !!data.options?.some((el) => el.cascading_fields?.length)
        },
        // TODO remove when custom sections are ready:
        // organizePersonalDetails(data) {
        //     return {
        //         title: {
        //             field_id: 'title',
        //             label: 'Title',
        //             value: data.title,
        //             field_type: 'input',
        //         },
        //         first_name: {
        //             field_id: 'first_name',
        //             label: 'First Name',
        //             value: data.first_name,
        //             field_type: 'input',
        //         },
        //         last_name: {
        //             field_id: 'last_name',
        //             label: 'Last Name',
        //             value: data.last_name,
        //             field_type: 'input',
        //         },
        //         email: {
        //             field_id: 'email',
        //             label: 'Email',
        //             value: data.email,
        //             field_type: 'email',
        //         },
        //         phone: {
        //             field_id: 'phone',
        //             label: 'Phone',
        //             value: data.phone,
        //             field_type: 'input',
        //         },
        //     }
        // },
        // TODO remove when custom sections are ready:
        // setPersonalValidations(data) {
        //     if (!data) return {};
        //     const objectMap = (obj, fn) =>
        //         Object.fromEntries(
        //             Object.entries(obj).map(
        //             ([k, v], i) => [k, fn(v, k, i)]
        //         )
        //     )
        //     return objectMap(data, (el) => {
        //         const fieldType = fieldTypes[el.field_type.toLowerCase()];
        //         return {
        //             required,
        //             ...fieldType.validators,
        //         }
        //     });
        // },
        // generateRandomString,
        setCurrentSection(section /* , next = true */) {
            // we don't check consent when go back
            // if (!this.isConsentPassed && next && !this.previewMode) return;
            // TODO remove when custom sections are ready:
            // we can switch between custom sections without consent and validation
            // &&
            // !this.getSelectedSection.custom &&
            // !section.custom
            if (section && this.selectedSectionId !== section.id) this.selectedSectionId = section.id;
        },
        // TODO remove, no more need
        getEntityPath(source, path, resultGenerator, i = 0, result = []) {
            const getEntityIndex = (entities, currPath) => {
                return entities.findIndex((el) => el[currPath.field] === currPath.value).toString();
            };
            const currIndex = getEntityIndex(source, path[i]);
            let currResult = [...result, currIndex];
            // if it was the last el generate result depending on case
            if (i === path.length - 1) return resultGenerator(currResult);
            else return this.getEntityPath(source[currIndex].entities, path, resultGenerator, i + 1, currResult);
        },
        // handleChangePersonalValue(data) {
        //     this.$set(this.personal_details, data.id, data.value);
        // },
        // used for entity copying:
        resetFields(data, values = {}, reset_id = true, path = []) {
            // TODO use link to entity object instead of generated 'path' property
            if (data.entities) {
                const newPath = [...path];
                // TODO handle other entity types if will be
                if (data.form_entity_type === "BLOCK") {
                    // new entitie's id:
                    data.block_fe_id = uuid.v4();
                    newPath.push(data.block_fe_id);
                }
                data.entities = data.entities.map((el) => this.resetFields(el, values, true, newPath));
                return data;
            } else {
                const result = { ...data, ...values };
                if (reset_id) {
                    result.field_fe_id = uuid.v4();
                }
                // set one copy_id for this set of copies to
                result.copy_id = this.current_copy;
                // reset path idetificator:
                const newPath = [...path];
                newPath.push(result.field_fe_id);
                const resetPath = [...data.path];
                resetPath.splice(-newPath.length, newPath.length, ...newPath);
                const pathString = resetPath.join("/");
                const pathParent = [...resetPath];
                pathParent.splice(-1, 1);
                result.path = resetPath;
                result.pathString = pathString;
                result.pathParent = pathParent;
                result.pathParentString = pathParent.join("/");
                // set new dependency:
                if (data.render_conditionally && data.condition) {
                    this.$set(this.dependencies, result.pathString, { condition: data.condition });
                }
                return result;
            }
        },
        handleValues(opt) {
          if (opt?.options?.length > 0) {
            opt.options.forEach(el => {
              {
                el.value = null;
                el.cascading_fields?.map(opt => {
                  opt.value = null;
                  if (opt.options?.length > 0) {
                    this.handleValues(el)
                  }
                })
              }
            })
          }
          else if (opt?.cascading_fields.length > 0) {
            opt.cascading_fields?.forEach(el => {
              el.value = null;
              if (el.options?.length > 0) {
                this.handleValues(el)
              }
            })
          }
        },
        copyEntity(payload, addElemToLast = false, saveAndNext = false) {
            const {
                entity,
                list = [],
                values = {},
                nextBlocks = {},
                previous = entity,
                clone = false,
                block_clone_id = null,
                properties = null,
            } = payload
            // * previous - we can manage where to insert new entity (after which element)

            this.current_copy = uuid.v4();
            // // note copies count of the current entity
            // if (!original.copy_count) original.copy_count = 0;
            // original.copy_count++;

            // alternative copy count calculdating:
            // const copyCount = originalSource.filter(el => el.original_entity === entityParams.value).length;

            const copy = cloneDeep(entity);

            // new values for fields:
            const resetValues = { value: null, ...values };
            // new entitie's id:
            // const uuidId = uuid.v4() // is reset in resetField method

            var copy_key_name = "";
            if (entity.form_entity_type === "BLOCK") {
                copy_key_name = "block_fe_id";
            }
            if (entity.form_entity_type === "FIELD") {
                copy_key_name = "field_fe_id";
            }

            // reset all fields' values:
            const newEntity = {
                ...this.resetFields(copy, resetValues, true),
                is_copy: true,
                // set new id depending on the current entity copies count
                // [entityParams.field]: uuidId,
                // [entityParams.field]: `${entityParams.value}_${original.copy_count}`,
                // original_entity: original.original_entity || entityParams.value,
                // [copy_key_name]:uuidId
                // ({...el,value: values[el.field_id] || "xxxx" })
                serial: copy.serial + 1, // TODO , this should be incremental
                order_serial:saveAndNext?copy.serial:copy.serial+1
            };
            if (clone) {
                if (newEntity.form_entity_type === "BLOCK") newEntity.entities = newEntity.entities.map((el) => this.resetFields(el, { value: values[el.field_id || el.field_fe_id] }, true));
            }

             //generating field_id for save next clone entity(linking) //TODO
             if(nextBlocks && Object.keys(nextBlocks).length === 0 && saveAndNext) {
                newEntity.entities = newEntity.entities.map((el) => {
                    nextBlocks.entities.map((nextBlk) => {
                        if(nextBlk.field_internal_name == el.field_internal_name) {
                            el.field_id = nextBlk.field_id
                        }
                    })
                    return el; 
                })
            }
            // newEntity[copy_key_name] = uuid.v4() // ? duplicate

            // disable mandatory only if we copy FIELD entity:
            if (entity.form_entity_type === "FIELD") resetValues.mandatory = false; // ! move it newEntity creating to enable

            // newEntity['xx'] = uuid.v4()
            // newEntity[copy_key_name] = uuidId // is reset in resetField method

            //mutate original, TODO, fix this later , copy should not mutate.
            // original[copy_key_name] = original.original_entity || entityParams.value,
            // except property copy_count
            // delete newEntity.copy_count;
            // add a new entity to the source list
            // ? add just after the original or after the last copy?

            const newIndex = addElemToLast ? list.length : list.findIndex((el) => el[copy_key_name] === previous[copy_key_name]) + 1;
            this.current_copy = "";
            if(saveAndNext) {
                list.splice(entity.index, 0, newEntity);
                Object.assign(newEntity, { is_LinkedCopy: true });
                Object.assign(newEntity, {block_id: nextBlocks.block_id});
                Object.assign(newEntity, {order_serial: entity.order_serial ? entity.order_serial : 1})
                Object.assign(newEntity, {serial: newEntity.order_serial})
            } else {
                list.splice(newIndex, 0, newEntity);
                Object.assign(newEntity, {order_serial: list instanceof Array ? list.length : 1})
                Object.assign(newEntity, {serial: newEntity.order_serial})
            }
            if(block_clone_id) Object.assign(newEntity, {block_clone_id : block_clone_id});
            if (properties) Object.assign(newEntity, properties);

            // TODO currently, field links are set separately for each field on render.
            // TODO links setting should be moved to the form-builder component.
            if (entity.form_entity_type === "BLOCK") {
                // don't create copies if not the source block is being copied:
                const isLinkedCopy = !!properties?.use_value_from;
                if (!isLinkedCopy) {
                    const linkedBlockGroup = this.linked_blocks.find((group) =>
                      group.includes(entity.block_id)
                    ) || null
                    console.log('linkedBlockGroup', linkedBlockGroup); // TODO remove
                    if (linkedBlockGroup) {
                        // may be used to go through all the sections:
                        const dependentBlockList = getAllEntitiesByKeyList(this.sections_data, {
                            use_value_from: entity.block_fe_id,
                        });
                        // may be used to go through the only current section:
                        // const dependentBlockList = getAllEntitiesByKeyList(list, {
                        //     use_value_from: entity.block_fe_id,
                        // });
                        // console.log('dependentBlockList', dependentBlockList); // TODO remove
                        dependentBlockList.forEach((linkedBlock) => {
                            // may be used to go through all the sections:
                            const parentSection = this.sections_data.find((section) => {
                                return !!getEntityByKeyList(section.entities, {
                                    block_fe_id: linkedBlock.block_fe_id,
                                });
                            });
                            const linkedCopyPrevious = getEntityByKeyList(parentSection.entities, {
                                block_fe_id: previous.block_fe_id,
                            });
                            // may be used to go through the only current section:
                            // const linkedCopyPrevious = getEntityByKeyList(list, {
                            //     use_value_from: previous.block_fe_id,
                            // });
                            const linkedCopyPayload = {
                                ...payload,
                                entity: linkedBlock,
                                list: parentSection.entities,
                                // list,
                                previous: linkedCopyPrevious,
                                properties: {
                                    use_value_from: newEntity.block_fe_id,
                                    serial: newEntity.serial, // the same serial should be set for the appropriate copy of a linked block
                                },
                            }
                            delete linkedCopyPayload.block_clone_id; // ? do we need this property?
                            this.copyEntity(linkedCopyPayload, addElemToLast, saveAndNext);
                        });
                    }
                }
            }

            return newEntity;
           
        },

        copyEntityWithoutSection( entity, list = [], values = {}, clone=false, saveAndNext= false) {
            this.current_copy = uuid.v4();
            const copy = cloneDeep(entity);
            const resetValues = { value: null, ...values }

            // var copy_key_name = "";
            // if (entity.form_entity_type === "BLOCK") {
            //     copy_key_name = "block_fe_id";
            // }
            // if (entity.form_entity_type === "FIELD") {
            //     copy_key_name = "field_fe_id";
            // }

            // reset all fields' values:
            const newEntity = {
                ...this.resetFields(copy, resetValues, true),
                is_copy: true,
                serial: copy.serial + 1,
                order_serial:saveAndNext?copy.serial:copy.serial+1
            };

            if (clone) {
                if (newEntity.form_entity_type === "BLOCK") newEntity.entities = newEntity.entities.map((el) => this.resetFields(el, { value: values[el.field_id || el.field_fe_id] }, true));
            }

            // disable mandatory only if we copy FIELD entity:
            if (entity.form_entity_type === "FIELD") resetValues.mandatory = false; // ! move it newEntity creating to enable

            this.current_copy = "";
            list.push(newEntity)
            Object.assign(newEntity, {order_serial: list instanceof Array ? list.length : 1})
            Object.assign(newEntity, {serial: newEntity.order_serial})
            return newEntity;
        },

        saveGapModalBlock({list, currentBlock, requiredIndex}) {
            list.splice(requiredIndex,1);
            list.splice(requiredIndex,0,currentBlock);
        },

        // reOrderBlocks() {},
        resetCopies() {
            const resetData = {
                reset: true,
                block: { is_copy: false },
                field: { is_copy: false },
            };
            this.sections_data = this.sections_data.map((el) => this.resetFieldsData(el, resetData));
        },
        async removeEntity(data) {
            // console.warn('removeEntity', data); // TODO remove
            // const { list: sourceList } = data; // use to access the original list
            const entity_id_prop = {
                BLOCK: "block_fe_id",
                FIELD: "field_fe_id",
            };
            if (data.is_saved) {
                // TODO remove
                // const apiMethods = {
                //     BLOCK: this.removeBlockMethod,
                //     FIELD: this.removeFieldMethod,
                // };
                // try {
                //     await apiMethods[data.form_entity_type](data.entity[entity_id_prop[data.form_entity_type]]);
                // } catch (error) {
                //     console.error("Failed to remove entity", error);
                //     throw error;
                // }
                // remove on save & next:
                const entityIdKey = entity_id_prop[data.form_entity_type];
                const entityIdValue = data.entity[entityIdKey];

                const section = data.section || this.sections_data.find((section) => {
                    return !!getEntityByKeyList(section.entities, {
                        [entityIdKey]: entityIdValue,
                    });
                });

                this.entitiesToRemove.push({
                    type: data.form_entity_type,
                    entity_id: entityIdValue,
                    section_id: section?.section_id, // we should store the section id because this item may be extracted from the sections_data
                })
            }
            const removeIndex = data.list.findIndex((el) => el[entity_id_prop[data.form_entity_type]] === data.entity[entity_id_prop[data.form_entity_type]]);
            data.list.splice(removeIndex, 1);
            // * the next code doesn't affect the original list but breaks the link to it.
            // * you may mutate each entity instead of this resetting:
            //maintaing order for linking block
            data.list = data.list.map((blocks, blockIndex) => {
                blocks.order_serial = blockIndex + 1;
                return blocks
            })
            // remove linked blocks:
            if (data.form_entity_type === 'BLOCK') {
                // don't remove copies if not the source block is being copied:
                const isLinkedCopy = !!data.use_value_from;
                if (!isLinkedCopy) {
                    const linkedBlockGroup = this.linked_blocks.find((group) =>
                        group.includes(data.entity.block_id)
                    ) || null
                    if (linkedBlockGroup) {
                        // may be used to go through all the sections:
                        const dependentBlockList = getAllEntitiesByKeyList(this.sections_data, {
                            use_value_from: data.entity.block_fe_id,
                        });
                        // may be used to go through the only current section:
                        // const dependentBlockList = getAllEntitiesByKeyList(data.list, {
                        //     use_value_from: data.entity.block_fe_id,
                        // });
                        dependentBlockList.forEach(async (linkedBlock) => {
                            // may be used to go through all the sections:
                            const parentSection = this.sections_data.find((section) => {
                                return !!getEntityByKeyList(section.entities, {
                                    block_fe_id: linkedBlock.block_fe_id,
                                });
                            });
                            await this.removeEntity({
                                ...data,
                                list: parentSection.entities,
                                // list: sourceList,
                                entity: linkedBlock,
                            })
                        });
                    }
                }
            }
        },
        async removeSavedEntities() {
            // console.log('removeSavedEntities', this.entitiesToRemove); // TODO remove
            const apiMethods = {
                BLOCK: this.removeBlockMethod,
                FIELD: this.removeFieldMethod,
            };
            try {
                await Promise.all(
                    this.entitiesToRemove.map(async (entityData) => {
                        // console.log('entityData', entityData); // TODO remove
                        const isToRemove = this.currentSection.section_id === entityData.section_id;
                        // console.log('isToRemove', isToRemove); // TODO remove
                        if (isToRemove && apiMethods[entityData.type]) {
                            await apiMethods[entityData.type](entityData.entity_id);
                        }
                    })
                );
            } catch (error) {
                console.error('error during entities removing:', error);
                throw error;
            }
        },
        linkedBlockGroup(CurrentDatablockData) {
            // check block_id to include copies in calculations:
            return (
                this.linked_blocks.find((group) =>
                  group.includes(CurrentDatablockData.block_id)
                ) || null
            );
        },
        isLinked(CurrentDatablockData) {
            if (!this.linkedBlockGroup(CurrentDatablockData)) return null;
            // check block_id to include copies in calculations:
            return this.linkedBlockGroup(CurrentDatablockData).some((el) => el === CurrentDatablockData.block_id);
        },
        prevSection() {
            this.btnClicked = false;
            this.saveDisabled=false 
            const check = !this.previewMode;
            let back = true;
            this.changeSection(-1, check, back);
            this.formStates.loading = false;
        },
        async nextSection() {
            this.blockhasErrors();
            this.firstBlockVisibleError();
            this.finalSubmit=true;
            this.saveDisabled = true;
            this.btnClicked = true;
            const check = !this.previewMode;
            await this.changeSection(1, check);
            this.finalSubmit=false;
        },
        /**
         * 
         * @param {Object} section Pass the section you eant to move to
         *  it must be COMPLETED, if you want to switch to it.
         */
        async changeSectionHandler(section) {
            if (this.isSectionSelectable(section)) {
                let end, start;
                start = this.sections_data.findIndex((secs) => secs.section_id === this.getSelectedSection.section_id);
                end = this.sections_data.findIndex((secs) => secs.section_id === section.id);
                const check = !this.previewMode;
                const back = true;
                this.hideErrors = true;
                this.isDivVisible = false;
                await this.changeSection(end - start, check, back);
            }
        },
        isSectionSelectable(section) {
            const status = this.getSectionStatus(section);
            const sectionIndex = this.sections_data.findIndex((item) => item.section_id === section.section_id);
            return !!(status === "COMPLETED" || status == "ERROR" || status == "PARTIALLY_COMPLETED" || sectionIndex === 0);
        },
        /**
         * 
         * @param {String} section_id : Switch to the section fo the form with it's section_id
         */
        async switchSection(section_id) {
            let end, start;
            start = this.sections_data.findIndex((secs) => secs.section_id === this.getSelectedSection.section_id);
            end = this.sections_data.findIndex((secs) => secs.section_id === section_id);
            const check = !this.previewMode;
            await this.changeSection(end - start, check);
        },
        async saveSection() {
            // if (this.saveSectionMethod && this.sections_to_save[this.getSelectedSection.section_id]) { // * uncomment if need to check if section has been changed
            if (this.saveSectionMethod) {   
                try {
                    await this.saveSectionMethod({
                        section_id: this.getSelectedSection.section_id,
                        data: this.getSelectedSection,
                    });
                    
                    this.saveDisabled = false;
                    // this.$set(this.sections_to_save, this.getSelectedSection.section_id, false); // * uncomment if need to check if section has been changed
                } catch (error) {
                    this.saveDisabled = false;
                    const resp = error.response.data;
                    const errorData = resp?.detail?.error;
                    const errorCode = errorData?.code;
                    if (formSavedErrorCodes.includes(errorCode)) {
                        this.resetCopies();
                        // reset links after data mutating:
                        this.reloadFormBuilder();
                    }

                    const newSectionsData = resp?.data?.sections;
                    if (newSectionsData) {
                        this.sections_data.forEach((el, i) => {
                            const sectionToReset = getEntityByKeyList(newSectionsData, { section_id: el.section_id });
                            if (sectionToReset) {
                                this.$set(this.sections_data, i, this.organizeFieldsData(sectionToReset));
                            }
                        });
                        this.reloadFormBuilder();
                    }

                    throw error;
                }
            }
        },
        fillAllDuplicateFields(){
            const duplicateFields = {...this.allDuplicateFields};

            this.getSelectedSection.entities.forEach((bl) => {
                if (bl.form_entity_type === "BLOCK") {
                    // bl.entities.forEach((field) => {
                    //     if (field.is_hide_duplicate_field) {
                    //         if (Object.keys(duplicateFields).includes(field.label) && field.isDuplicate) {
                    //             field.value = duplicateFields[field.label];
                    //         }
                    //         else if(!field.isDuplicate){
                    //             duplicateFields[field.label] = field.value
                    //         }
                    //     }
                        
                    // })
                }
                if (bl.form_entity_type === "FIELD") {
                    if (bl.is_hide_duplicate_field ) {
                        if (Object.keys(duplicateFields).includes(bl.label) && bl.isDuplicate) {
                            bl.value = duplicateFields[bl.label];
                        }
                        else if(!bl.isDuplicate){
                            duplicateFields[bl.label] = bl.value;
                        }
                    }
                    
                }
            })
            this.allDuplicateFields = duplicateFields;
        },
        async changeSection(delta, check = false, back) {
            try {
                if (check && !back && !this.isAddReadOnly) {
                    // TODO remove when custom sections are ready:
                    // // custom sections:
                    // if (this.changeCustomSection[this.getSelectedSection.section_id]) {
                    //     const goNext = await this.changeCustomSection[this.getSelectedSection.section_id]();
                    //     if (!goNext) return;
                    // // other sections
                    // } else {
                    this.btnClicked = true
                    this.saveDisabled = true;
                    this.getCurrentValidations.$touch();
                    this.getGapValidations.$touch();
                    if (delta > 0 && !this.isConsentPassed) {
                        this.finalSubmit=false;
                        this.saveDisabled=false 
                        return
                    }
                    if (delta < 0 && !this.isConsentPassed) {
                        this.consentField.value = "disagree";
                    }
                    // if form is still invalid after consent was set the sections changing stops;
                    // also, check if there is an invalid gap in current section's gap validation blocks
                    let isInvalidGap = this.getGapValidations.$invalid;

                    if (isInvalidGap) {
                        // gap errors recalculation will be executed from the autofillGaps() method
                        this.$refs?.dynamicFormBuilder.autofillGaps();
                        isInvalidGap = this.getGapValidations.$invalid;
                    }

                    if (this.getCurrentValidations.$invalid || isInvalidGap) {
                        this.saveDisabled = false;
                        this.finalSubmit=false;
                        mutations.setSectionStatus(this.selectedSectionId, "ERROR", this.sectionEnvStatusProp);
                        logValidationErrors(this.getCurrentValidations.entities, this.getGapValidations);

                        console.log("isInvalidGap", isInvalidGap)

                        if (isInvalidGap) {
                            this.$refs?.dynamicFormBuilder.scrollToGapOnSave();
                        } else {
                            this.scrollToInvalidField();
                        }
                        return;
                    }
                    // if(this.isCandidate){this.fillAllDuplicateFields();}
                    await this.removeSavedEntities();
                    await this.saveSection();
                    this.getGapValidations.$reset();
                    // }
                }
                this.saveDisabled = false;
                const newSection = this.getSections[this.getSelectedSectionIndex + delta];
                
                // TODO remove when custom sections are ready:
                // const newSection = this.getSections[this.getSelectedSectionIndexExtended + delta];

                // check consent in case user goes forward:
                if (!this.isConsentPassed && delta > 0 && !this.previewMode) return;
                if(!back){
                    mutations.setSectionStatus(this.selectedSectionId, "COMPLETED", this.sectionEnvStatusProp);
                }
                this.setCurrentSection(newSection /* , delta > 0 */);
                this.resetCopies();
                // reset links after data mutating.
                // Don't reset if it is a final submit:
                if (!this.finalSubmit) this.reloadFormBuilder();
            } catch (error) {
                this.saveDisabled = false;
                console.warn("change section error:", error);
            }
        },
        validateAllSections(){
            for(let sec of this.getSections){
                if(sec.status == 'ERROR'){
                    return true;
                }
            }
            return false;
        },
        async saveForm() {
            // this.$v.$touch();
            this.finalSubmit=true;
            const check = !this.previewMode;    
            this.$refs?.dynamicFormBuilder.scrollToGapOnSave();
            await this.changeSection(1, check);
            let secHasError = this.validateAllSections();
            if (secHasError || !this.isConsentPassed) {
                this.finalSubmit = false;
                this.isError = true;
                logValidationErrors(this.$v.sections_data);
                return;
            }
            this.isError = false;
            // TODO
            try {
                this.sumbitForm();
                // this.sections_data = result;
                this.$v.$reset();
                this.finalSubmit = false;
            } catch (error) {
                this.finalSubmit = false;
                console.warn("save form error", error);
            }
        },
        sumbitForm() {
            this.$emit("submit", {
                section_id: this.getSelectedSection.section_id,
                data: this.getSelectedSection,
            });
        },
        printSection() {
            const form_builder = this.$refs?.dynamicFormBuilder;
            const non_field = Object.keys(form_builder?.$refs)?.filter((el) => !el.includes("form-field"));
            let consent = form_builder?.$refs?.["form-field-consent"];

            for (const item in non_field) {
                consent = {...consent, ...form_builder?.$refs[non_field?.[item]]?.[0]?.$refs?.["form-field-consent"] };
                form_builder?.$refs[non_field?.[item]]?.[0]?.$el.classList.remove("bg-gray-100")
            }
            for (const field in consent) {
                consent[field]?.$refs["consent-field-block"]?.classList.remove("max-h-36");
                consent[field]?.$refs["consent-field-block"]?.classList.remove("overflow-auto");
                consent[field]?.$refs["consent-field-block"]?.classList.remove("scroll-bar");
            }
            window.print();
            for (const field in consent) {
                consent[field]?.$refs["consent-field-block"]?.classList.add("max-h-36");
                consent[field]?.$refs["consent-field-block"]?.classList.add("overflow-auto");
                consent[field]?.$refs["consent-field-block"]?.classList.add("scroll-bar");
            }
            for (const item in non_field) {
                consent = {...consent, ...form_builder?.$refs[non_field?.[item]]?.[0]?.$refs?.["form-field-consent"] };
                form_builder?.$refs[non_field?.[item]]?.[0]?.$el.classList.add("bg-gray-100")
            }
        },
        showPreviewModal() {
            this.$refs.preview_form_modal.showModal();
        },

        /***
         * set section status of form sections
         * initially it is returned via backend
         * then calculated to show status icons at the sidebar
         * @params {section} current section
         * 
         */
        sectionStatus(section) {
            const status = this.getSectionStatus(section);
            let error = !!section.has_error || section.$v.$error || status === "ERROR";
            // to do complete with api changes
            let completed = status === "COMPLETED"
            let current = (section.id === this.selectedSectionId || status === "PARTIALLY_COMPLETED") && !completed && !error;
            let pending = ((!error && !current && !completed) || status === "PENDING") && !current;
            // let partially_completed =

            return { error, completed, current, pending };
        },
        getSectionStatus(section) {
            const mainStatus = section.status;
            const envStatus = section[this.sectionEnvStatusProp];
            return mainStatus === 'COMPLETED' ? envStatus : mainStatus;
        },
        // TODO should be moved to the gapValidationGroup.vue component to call it only on demand:
        // Use for gap validation groups init. For any changes use other methods
        generateGapErrors() {
            // reset gap vales
            const gapErrors = {};
            let now = new Date();

            const fetchGapErrors = (entity, currentSection, currentBlock, currentGapGroup = null) => {
                if (entity.form_entity_type === "FIELD" && entity.gap_validation !== null) {
                    let start = null;
                    let end = null;
                    const { gap_validation } = entity;

                    let timelineObj = {};
                    let isInvalid = !entity.value;
                    // if the block has been created on the FE during previous fetchGapErrors() use previouse invalid_gap value:
                    if (currentGapGroup) {
                        // console.log('fetchGapErrors currentGapGroup', currentGapGroup); // TODO remove
                        const currentGap = currentGapGroup.timeline?.find((el) => el.block.block_fe_id === currentBlock.block_fe_id)
                        // console.log('fetchGapErrors currentBlockFromGroup', currentGap); // TODO remove
                        if (currentGap) {
                            isInvalid = !!currentGap.invalid_gap;
                        }
                    }

                    if (gap_validation.relative) {
                        let gapEndDate;
                        let gapStartDate
                        if (gap_validation.till_present) {
                            gapEndDate = now;
                        } else {
                            gapEndDate = getFieldById(this.sections_data, gap_validation.end_date).value || ""
                        }
                        end = gapEndDate || now;
                        gapStartDate = getFieldById(this.sections_data, gap_validation.start_date).value || ''
                        if (gapStartDate && gapEndDate) {
                            start = new Date(gapStartDate);
                            timelineObj = {
                                current: false, // TODO check if we should set true in "till present" case; check if we use it
                            };
                        }
                    } else {
                        start = subtractMonths(now, gap_validation.gap_history);
                        end = now;
                        timelineObj = {
                            current: false, // TODO check if we should set true in "till present" case; check if we use it
                        };
                    }

                    const originalBlock = getEntityByKeyList(this.sections_data, { block_fe_id: currentBlock.block_id });
                    const originalField = getEntityByKeyList(this.sections_data, { field_fe_id: entity.field_id });
                    if (!gapErrors[originalBlock.block_id]) {
                        gapErrors[originalBlock.block_id] = {
                            originalBlock,
                            originalField,
                            section: currentSection,
                            start,
                            end,
                            timeline: [],
                        }
                    }
                    // create a gap entity with a linked field
                    // * note: the 'start' and 'end' properties should be set for invalid gap (set in findGapPeriods())
                    gapErrors[currentBlock.block_id].timeline.push({
                        ...timelineObj,
                        field: entity,
                        block: currentBlock,
                        invalid_gap: isInvalid,
                    });

                } else if (entity.form_entity_type === "BLOCK") {
                    const existingGapGroup = this.gap_values[entity.block_id] || null;
                    entity.entities.forEach((ent) => fetchGapErrors(ent, currentSection, entity, existingGapGroup));
                }
            };

            for (const section of this.sections_data) {
                for (const entities of section.entities) {
                    fetchGapErrors(entities, section);
                }
            }

            // reset gap values after we used all the necessary data:
            this.gap_values = {};

            this.gap_errors = {
                gaps: gapErrors,
                gapErrorsView: true,
                message: "Please provide relevant information for below mentioned gaps.",
                id: uuid.v4(),
            }

            Object.values(this.gap_values).forEach((gapGroup) => {
                // console.log('gapGroup', gapGroup); // TODO remove
                this.findGapPeriods(gapGroup, false);
            });
        },
        /**
         * 
         * @param {*} dateList array of date objects 
         * @returns an array of gap objects with gap timeline of each block
         *        with timeline of proper blocks (valid gaps) and missed gap
         */
        findGapPeriods(gapGroup, notifications = true) {
            const getGapValue = (gap) => {
                if (!gap.field.value) return null;
                return JSON.parse(gap.field.value);
            }
            let resultTimeline = [];

            // if group is linked we check the 1st block's timeline:
            const isLinked = !!gapGroup.originalBlock.use_value_from;

            if (!isLinked) {
                // in case we don't have a period to validate only valid gaps will be deiplayed
                const toCheckInvalid = gapGroup.start && gapGroup.end; // TODO DEL-8300 check
    
                const gapValidation = gapGroup.originalField.gap_validation;
                const validGaps = gapGroup.timeline.filter((el) => {
                    if (el.invalid_gap || !el.field) return false;
                    return true;
                    // we can additionally check a value:
                    // const elValue = getGapValue(el);
                    // return !!(elValue?.from && elValue?.to);
                });
                const invalidGaps = gapGroup.timeline.filter((el) => el.invalid_gap);
                // console.log('old validGaps', validGaps); // TODO remove
                // console.log('old invalidGaps', invalidGaps); // TODO remove
    
                const sortedDates = validGaps
                    .map((el) => {
                        const elValue = getGapValue(el);
                        const startDate = elValue?.from ? elValue.from.split(" ")[0] : null; 
                        const endDate = elValue?.to ? elValue.to.split(" ")[0] : null;
                        const actualEndDate = elValue.current ? new Date() : endDate;
    
                        return {
                            ...el,
                            start: startDate ? new Date(startDate.toString()) : '',
                            end: actualEndDate ? new Date(actualEndDate.toString()) : '',
                        }
                    })
                    .sort((a, b) => a.start ? a.start - b.start : -1);
    
                /**
                 * 
                 * @param {string} date
                 * @param {number} days - number to add
                 * @returns calculated date
                 */
                const computeDays = (date, days = 0) => {
                    let result = new Date(date);
                    result.setDate(result.getDate() + days);
                    return result;
                };
                const findGap = (gaps, { start, end }, onlyInvalid = false) => {
                    const result = gaps.find((el) => {
                        if (!el.field) return false;
                        const gapValue = getGapValue(el);
                        if (!gapValue) return false;
                        if (onlyInvalid && !el.invalid_gap) return false;
                        const {from, to} = gapValue;
                        return start === from && end === to;
                    });
                    return result
                }
                // use to check if gap is linked to the original (first) block that can't be removed:
                const checkOriginal = (gap) => {
                    return gap.field?.field_fe_id === gapGroup.originalField.field_fe_id;
                }
                const gapWithOriginal = gapGroup.timeline.find(checkOriginal);
    
                // result lists:
                const gapTimeline = []; // is used for invalid gaps
                const newInvalidGapValues = [];

                const resultValidTimeline = sortedDates
                    // set invalid_gap_data:
                    .map(el => ({
                        ...el,
                        invalid_gap_data: null,
                    }));

                // console.log('resultValidTimeline', resultValidTimeline); // TODO remove
                resultTimeline.push(...resultValidTimeline);
    
                // Here, the data for invalid gaps is being prepared. We don;t need it in case we don't have a period to validate
                if (toCheckInvalid) {
                    // console.log('toCheckInvalid'); // TODO remove
                    // if valid gap is zero we default it to 1 for easier calculations
                    const validGap = gapValidation.valid_gap || 1;
              
                    const startDate = gapGroup.start;
                    const endDate = gapGroup.end;
                    // console.log('Start Date', startDate); // TODO remove
        
                    // timeline to calculate invalid gaps
                    const filledValidGaps = sortedDates.filter(el => el.start && el.end);
                    // console.log('filledValidGaps', filledValidGaps); // TODO remove
                    let timeline = [
                        {
                            invalid_gap: false,
                            block: gapGroup.originalBlock,
                            field: gapGroup.originalField,
                            start: startDate,
                            end: startDate,
                        },
                        // exclude valid gaps with empty values
                        ...filledValidGaps,
                        {
                            invalid_gap: false,
                            block: gapGroup.originalBlock,
                            field: gapGroup.originalField,
                            start: endDate,
                            end: endDate,
                        },
                    // don't take into account periods out of the period to validate:
                    ].filter((el) => !(el.start > new Date(gapGroup.end)) && !(el.end < new Date(gapGroup.start)))
                        

                    timeline.forEach((item) => {
                        item.effStart = item.start.getTime() < startDate.getTime() ? startDate : item.start;
                        item.effEnd = item.end.getTime() > endDate.getTime() ? endDate : item.end;
                    });
    
                    for (let i = 0; i < timeline.length - 1; i++) {
                        const currentDateRange = timeline[i];
                        const nextDateRange = timeline[i + 1];
              
                        let endDate = currentDateRange.effEnd;
                        const nextStartDate = nextDateRange.effStart;
        
                        // calculating gaps, check if the current period is overlapped by another:
                        const wrapperDateRangeList = timeline.filter((el) => endDate > el.effStart && endDate < el.effEnd);
                        const isOverlapped = !!wrapperDateRangeList.length;
                        // console.log('isOverlapped', isOverlapped, wrapperDateRangeList); // TODO remove
                        // if period end date is within another period consider the wrapper's end date:
                        if (isOverlapped) {
                            // take the latest end date into account:
                            const latestWrapper = wrapperDateRangeList
                                .sort((a, b) => b.effEnd > a.effEnd ? 1 : -1)[0];
                            endDate = latestWrapper.effEnd;
                        }
        
                        // isLastGap used to prevent overlaping with the first day of the next gap if current isn't the last:
                        const isLastGap = nextStartDate.getTime() === timeline.at(-1).end.getTime()
                        // isFirstGap used to prevent overlaping with the last day of the previous gap if current isn't the first:
                        const isFirstGap = i === 0
              
                        const timeDifference = new Date(nextStartDate).getTime() - new Date(endDate).getTime();
                        const gapInDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
        
                        // TODO remove
                        // // set invalid_gap_data:
                        // timeline[i].invalid_gap_data = null;
                        // gapTimeline.push(timeline[i]);
              
                        if (gapInDays > validGap) {
                            const start = formatDate(computeDays(new Date(endDate), isFirstGap ? 0 : 1)); // prevent overlaping with the last day of the previous gap
                            const end = formatDate(computeDays(new Date(nextStartDate), isLastGap ? 0 : -1)); // prevent overlaping with the first day of the next gap
        
                            const alreadyExistGap = findGap(invalidGaps, {start, end}, true);
                            if (alreadyExistGap) {
                                // console.log('alreadyExistGap', alreadyExistGap); // TODO remove
                                alreadyExistGap.invalid_gap_data = {
                                    start,
                                    end,
                                };
                                gapTimeline.push(alreadyExistGap);
                                continue;
                            } else {
                                const invalidGapValue = {
                                    from: start,
                                    to: end,
                                    current: isLastGap, // TODO check if we should set true in "till present" case && last gap
                                    // current: gapValidation.till_present && isLastGap,
                                }
            
                                newInvalidGapValues.push(invalidGapValue);
                            }
                        }
                    }
                }
                // console.log('gapTimeline', gapTimeline); // TODO remove
                // console.log('newInvalidGapValues', newInvalidGapValues); // TODO remove
                // console.log('invalidGaps after', invalidGaps); // TODO remove
    
                resultTimeline.push(...gapTimeline);
                // remove unused blocks (except original)
                // original block can't be removed - clear and reuse it
                invalidGaps
                    .forEach((gap) => {
                        // console.log('old invalid gap', gap); // TODO remove
                        let isUsed;
                        const gapValue = gap.field ? getGapValue(gap) : undefined;
                        if (!gapValue) isUsed = false;
                        else {
                            const {from, to} = gapValue;
                            isUsed = !!findGap(resultTimeline, {
                                start: from,
                                end: to,
                            })
                        }
                        const isOriginal = checkOriginal(gap);
    
                        if (!isUsed) {
                            if (isOriginal) {
                                // console.log('old invalid gap original'); // TODO remove
                                gap.field.value = null;
                                gap.invalid_gap_data = null;
                                // if !toCheckInvalid we don't have invalid gap we can link this block to
                            } else if (gap.block) {
                                // console.log('old invalid gap to remove'); // TODO remove
                                this.removeEntity({
                                    entity: gap.block,
                                    list: gapGroup.section.entities,
                                    is_saved: !gap.block.is_copy, // is_copy is a frontend value that mean that block hasn't been saved yet
                                    form_entity_type: gap.block.form_entity_type,
                                })
                            }
                        }
                    });
    
                // console.log('invalidGaps', invalidGaps); // TODO remove
                // console.log('newInvalidGapValues', newInvalidGapValues); // TODO remove
                // find block with empty data or create block for each new invalid gap:
                let currentBlockCount = gapGroup.timeline.filter((el) => !!el.block).length;
                newInvalidGapValues.forEach((gapValue) => {
                    const { from, to } = gapValue;
                    const emptyFieldGap = invalidGaps.find((el) => el.field && !el.field.value);
                    if (emptyFieldGap) {
                        // console.log('emptyField', emptyFieldGap); // TODO remove
                        emptyFieldGap.invalid_gap_data = {
                            start: from,
                            end: to,
                        };
                        emptyFieldGap.field.value = JSON.stringify(gapValue);
                        resultTimeline.push(emptyFieldGap);
                    } else {
                        const isMaxCountReached = currentBlockCount >= gapGroup.originalBlock.block_repeat_max;
                        const isLastBlock = gapGroup.originalBlock.block_repeat_max - currentBlockCount === 1;
    
                        const newGapData = {
                            field: null,
                            block: null,
                            invalid_gap: true,
                            invalid_gap_data: {
                                start: from,
                                end: to,
                            },
                        }

                        if (!isMaxCountReached) {
                            currentBlockCount++;
                            const newBlock = this.copyEntity({
                                entity: gapGroup.originalBlock,
                                list: gapGroup.section.entities,
                                clone: true,
                            });
                            // ? move this to the copyEntity() ?
                            newBlock.entities.forEach((el) => {
                                if (el.options.length > 0) {
                                    el.options.forEach((opt) => {
                                        this.handleValues(opt);
                                    })
                                }
                            });
                            // console.log('newBlock', newBlock) // TODO remove
                            // console.log('gapGroup.originalField', gapGroup.originalField) // TODO remove
                            const newField = getEntityByKeyList(newBlock.entities, { field_id: gapGroup.originalField.field_fe_id });
                            // console.log('newField', newField) // TODO remove
                            newField.value = JSON.stringify(gapValue);

                            newGapData.field = newField;
                            newGapData.block = newBlock;

                            if (isLastBlock) {
                                if (notifications) this.$toast.warning("The maximum period count has been reached");
                            }
                        } else {
                            if (notifications) this.$toast.error("Cannot fill a gap. The maximum period count has already been reached");
                        }
                        resultTimeline.push(newGapData);
                    }
                });

                // console.log('gapWithOriginal', gapWithOriginal); // TODO remove
                // console.log('gapWithOriginal.invalid_gap', gapWithOriginal.invalid_gap); // TODO remove
    
                // console.log('resultTimeline 1', resultTimeline); // TODO remove
                // * The original block shouldn't be removed:
                if (!resultTimeline.length) {
                    // console.log('!resultTimeline.length'); // TODO remove
                    gapWithOriginal.invalid_gap = true;
                    resultTimeline.push(gapWithOriginal);
                } else if (gapWithOriginal.invalid_gap && !gapWithOriginal.invalid_gap_data) {
                    // reuse the original block if it hasn't been used and there are valid gaps instead one of blocks
                    // console.log('reuse empty original without invalid gaps', gapWithOriginal); // TODO remove
                    const gapToRemove = resultTimeline[0];
                    console.warn('valid gapToRemove', gapToRemove); // TODO remove
                    const { block: blockToRemove } = gapToRemove;
                    
                    useValuesFrom(gapWithOriginal.block, blockToRemove, true);
                    gapWithOriginal.invalid_gap = false;
                    gapWithOriginal.invalid_gap_data = null;
                    // replace first timeline item with item that includes the original block:
                    resultTimeline[0] = gapWithOriginal;

                    this.removeEntity({
                        entity: blockToRemove,
                        list: gapGroup.section.entities,
                        is_saved: !blockToRemove.is_copy, // is_copy is a frontend value that mean that block hasn't been saved yet
                        form_entity_type: blockToRemove.form_entity_type,
                    })
                }
    
                // console.log('newInvalidGaps', resultTimeline.filter((el) => el.invalid_gap)); // TODO remove
                // console.log('resultTimeline 2', resultTimeline); // TODO remove
            }
            this.$set(gapGroup, 'timeline', resultTimeline);
        },
        scrollToInvalidField() {
            this.$nextTick(()=>{
                const regularFieldWithError = document.querySelector(REGULAR_FIELD_WITH_ERROR_SELECTOR);
                const cascadingFieldWithError = document.querySelector(CASCADING_FIELD_WITH_ERROR_SELECTOR);

                if (regularFieldWithError && !cascadingFieldWithError) {
                    return regularFieldWithError.scrollIntoView(SCROLL_OPTIONS);
                }

                cascadingFieldWithError?.scrollIntoView(SCROLL_OPTIONS);
            });
        },
        resetLinkedBlocks() {
            // console.log('resetLinkedBlocks'); // TODO remove
            this.linked_blocks.forEach((linkedBlockGroup) => {
                const sourceBlock = getEntityByKeyList(this.sections_data, {
                    use_value_from: null,
                    block_fe_id: (val) => linkedBlockGroup.includes(val),
                });
                // console.log('sourceBlock', sourceBlock); // TODO remove
                const listToReset = getAllEntitiesByKeyList(this.sections_data, {
                    use_value_from: sourceBlock.block_fe_id,
                });
                // console.log('listToReset', listToReset); // TODO remove
                const sourceCopyList = getAllEntitiesByKeyList(this.sections_data, {
                    block_id: sourceBlock.block_fe_id,
                    block_fe_id: (val) => val !== sourceBlock.block_fe_id,
                });
                // console.log('sourceCopyList', sourceCopyList); // TODO remove

                // remove all linked blocks' copies except copies of source block:
                listToReset.forEach(async (linkedBlock) => {
                    const parentSection = this.sections_data.find((section) => {
                        return !!getEntityByKeyList(section.entities, {
                            block_fe_id: linkedBlock.block_fe_id,
                        });
                    });
                    // * for some reason BE may save data in an incorrect way so we need this workaround:
                    // console.log('parentSection', parentSection, linkedBlock); // TODO remove
                    if (!parentSection) return;

                    const copyList = getAllEntitiesByKeyList(this.sections_data, {
                        block_id: linkedBlock.block_fe_id,
                        block_fe_id: (val) => val !== linkedBlock.block_fe_id,
                    });
                    // console.log('copyList', copyList); // TODO remove
                    await copyList.forEach(async (blockCopy) => {
                        // console.log('blockCopy to remove', blockCopy); // TODO remove
                        await this.removeEntity({
                            is_saved: !blockCopy.is_copy, // is_copy is a frontend value that mean that block hasn't been saved yet
                            form_entity_type: blockCopy.form_entity_type,
                            list: parentSection.entities,
                            entity: blockCopy,
                        });
                    });
                    // and create new copies with new links in accordance to source copies:
                    sourceCopyList.forEach((sourceBlockCopy) => {
                        // find the last block in a copies list:
                        const linkedCopyPrevious = getAllEntitiesByKeyList(parentSection.entities, {
                            block_id: linkedBlock.block_fe_id,
                        })?.at(-1);
                        const linkedCopyPayload = {
                            entity: linkedBlock,
                            list: parentSection.entities,
                            previous: linkedCopyPrevious,
                            properties: {
                                use_value_from: sourceBlockCopy.block_fe_id,
                                serial: sourceBlockCopy.serial, // the same serial should be set for the appropriate copy of a linked block
                            },
                        }
                        this.copyEntity(linkedCopyPayload);
                    })
                });
            });
        },
        handleWindowResize() {
           this.getWindowSize();
         },
         getWindowSize() {
           this.windowWidth = window.innerWidth;
         },
         getEntityByKeyListConsent(list, keys) {
              for (const item of list) {
                const isEqual = isEquel(item, keys);
                const isConsentShown = !this.dependencies[item.pathString]?.hidden
                if(isEqual && isConsentShown) {
                  this.modelConsentList.push(item);
                }
                if(item.entities?.length) {
                  this.getEntityByKeyListConsent(item.entities, keys);
                }
                const cascadingFields = getCascadingFields(item)
                if (cascadingFields) {
                    this.getEntityByKeyListConsent(cascadingFields, keys);
                }
              }
              return this.modelConsentList;
            },
            checkAllMandatoryConsent(val) {
                if(val && isJson(val) && JSON.parse(val).value === "agree") {
                    return true;
                }
                else if(val && val.value === "agree"){
                    return true
                }
                else {
                    return false
                }
            }
    },
   
};
